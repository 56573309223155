// Absolute imports
import React, { Component } from 'react';

// Relative imports
import Home from '../../enviroments/introductionPages/startPage/startPage';
import RoleOfOfficer from '../../enviroments/introductionPages/roleOfOfficer/roleOfOfficer';
import Context from '../../enviroments/introductionPages/explanation/explanation';
import WorkSpace from '../../enviroments/gameEnviroments/workSpace/workSpace';
import SuspectMonitor from '../../enviroments/gameEnviroments/suspectMonitor/suspectMonitor';
import RoundTable from '../../enviroments/gameEnviroments/roundTable/roundTable';
import Journals from '../../enviroments/gameEnviroments/journals/journals';
import JournalDetail from '../../enviroments/gameEnviroments/journalDetail/journalDetail';
import TipsAndTricks from '../../enviroments/introductionPages/tipsAndTricks/tipsAndTricks';
import Parts from '../../enviroments/introductionPages/parts/parts';
import HowItWorks from '../../enviroments/introductionPages/howItWorks/howItWorks';
import Chat from '../../enviroments/gameEnviroments/chat/chat';
import Instruction from '../../enviroments/gameEnviroments/instructions/instructions';
import Decision from '../../enviroments/gameEnviroments/decision/decision';
import Endpage from '../../enviroments/gameEnviroments/endpage/endpage';

// Import from same folder
import types from './types';
import LevelCase from '../../utils/models/levelCase';
import Feedback from '../../enviroments/gameEnviroments/feedback/feedBack';
import GameSolved from '../../enviroments/gameEnviroments/gameSolved/gameSolved';
import CaseExpired from '../../enviroments/gameEnviroments/caseExpired/caseExpired';

interface State {
  isPlaying: boolean;
  component: any;
  timeLeft: number;
}

let timer = <></>;
class DynamicComponent extends Component<types, State> {
  milliseconds!: number;
  countdown!: number;

  constructor(attr: types) {
    super(attr);
    this.state = {
      isPlaying: false,
      component: null,
      timeLeft: 0
    };
  }

  changePage = (page: string, item?: LevelCase) => {
    this.renderEnviroment(page, item);
  };

  componentDidMount = () => {
    const currentPage = sessionStorage.getItem('currentPage');
    const item = sessionStorage.getItem('item');
    if (currentPage) {
      this.renderEnviroment(
        currentPage,
        item !== 'undefined' && typeof item === 'string'
          ? JSON.parse(item)
          : undefined
      );
    } else {
      this.setState({
        component: <Home page={this.changePage} />
      });
    }
  };

  renderEnviroment = (page: string, item?: LevelCase) => {
    this.setState({ component: null });

    sessionStorage.setItem('currentPage', page);
    sessionStorage.setItem('item', JSON.stringify(item));
    switch (page) {
      case 'startPage':
        timer = <></>;
        this.setState({
          component: <Home page={this.changePage} />
        });
        break;
      case 'context':
        this.setState({
          component: <Context page={this.changePage} />
        });
        break;
      case 'roleOfOfficer':
        this.setState({
          component: <RoleOfOfficer page={this.changePage} />
        });
        break;
      case 'workSpace':
        this.setState({
          component: <WorkSpace page={this.changePage} timer={this.startTimer} />
        });
        break;
      case 'suspectMonitor':
        this.setState({
          component: <SuspectMonitor page={this.changePage} />
        });
        break;
      case 'roundTable':
        this.setState({
          component: <RoundTable page={this.changePage} />
        });
        break;
      case 'journals':
        this.setState({
          component: <Journals page={this.changePage} />
        });
        break;
      case 'journalDetail':
        this.setState({
          component: <JournalDetail page={this.changePage} item={item} />
        });
        break;
      case 'parts':
        this.setState({
          component: <Parts page={this.changePage} />
        });
        break;
      case 'howItWorks':
        this.setState({
          component: <HowItWorks page={this.changePage} />
        });
        break;
      case 'tipsAndTricks':
        this.setState({
          component: <TipsAndTricks page={this.changePage} />
        });
        break;
      case 'chat':
        this.setState({
          component: <Chat page={this.changePage} />
        });
        break;
      case 'instruction':
        this.setState({
          component: <Instruction page={this.changePage} />
        });
        break;
      case 'decision':
        this.setState({
          component: <Decision page={this.changePage} />
        });
        break;
      case 'endPage':
        this.setState({
          component: <Endpage page={this.changePage} />
        });
        break;
      case 'feedback':
        this.setState({
          component: <Feedback page={this.changePage} />
        });
        break;
      case 'gameSolved':
        this.setState({
          component: <GameSolved page={this.changePage} />
        });
        break;
      case 'caseExpired':
        this.setState({
          component: <CaseExpired page={this.changePage} />
        });
        break;
      default:
        alert(page);
        this.setState({
          component: <WorkSpace page={this.changePage} timer={this.startTimer} />
        });
        break;
    }
  };

  startTimer = (time: number) => {
    this.countdown = time;
    this.setState({ isPlaying: true });
    const interval = setInterval(() => {
      this.countdown = this.countdown - 1;
      if (this.countdown === 0) {
        sessionStorage.setItem('sessionEnded', 'true');
        clearInterval(interval);
        this.countdown = this.milliseconds;
        this.setState({
          isPlaying: false
        });
      }
    }, 1000);
  };

  render = () => {
    return (
      <div className="dynamicComponent">
        {this.state.component}
        {timer}
      </div>
    );
  };
}

export default DynamicComponent;
