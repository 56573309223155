import React, { useState } from 'react';
import '../dynamicRenderer/dynamicComponent';
import types from './types';
import { useGameState } from '../../contexts/gameContext';
import { useInterval } from '../../hooks/useInterval';
import { OutOfTimeIcon, SolvedIcon } from '../../styling/icons/icons';
import CaseDecisions from '../../utils/models/caseDecision';
import Level from '../../utils/models/level';

import { time } from 'console';

const isCaseSolved = (cases: CaseDecisions[] | undefined, id: string) => {
  // get current case data it only exists if it has already been solved
  let caseData: CaseDecisions;
  if (cases) {
    caseData = cases.filter((item) => item.id === id)[0];
    return caseData?.id !== undefined;
  }
  return false;
};

const getRemainingTime = (
  timeInSeconds: number,
  countdown: number,
  addition: string | undefined
) => {
  const standardMinutes = timeInSeconds / 7 / 60;
  const time = countdown / 1000 / standardMinutes;
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);

  const militaryMin =
    minutes.toString().length < 2 ? '0' + minutes.toString() : minutes.toString();
  const militarySec =
    seconds.toString().length < 2 ? '0' + seconds.toString() : seconds.toString();

  return (
    <>
      <p>{militaryMin}:</p>
      <p>{militarySec}</p>
      <p>&nbsp; {addition}</p>
    </>
  );
};

const getTimerStyling = (index: number) => {
  switch (index) {
    case 0: {
      return 'red';
    }
    case 1: {
      return 'yellow';
    }
    case 2: {
      return 'gray';
    }
  }
  return '';
};

const setStateClass = (level: Level | undefined, countdown: number) => {
  if (!level) {
    console.error('No data here!');
    return '';
  }
  let index = 0;

  if (countdown <= level?.timeTransition * 1000) {
    index = 0;
  } else if (countdown <= level?.timeTransition * 2000) {
    index = 1;
  } else {
    index = 2;
  }

  return getTimerStyling(index);
};

function SmallTimer(attr: types) {
  const { level, cases } = useGameState();
  const milliseconds = Number(attr.seconds * 1000);
  const [countdown, setCountdown] = useState(milliseconds);
  const [outOfTime, setOutOfTime] = useState(<></>);

  let isPlaying = false;
  let finished = false;
  let timeState = '';
  const mainCountDownTime = attr.MainTimerAmount * 1000;

  const ended = sessionStorage.getItem('sessionEnded');
  const loaded = sessionStorage.getItem('levelLoaded');

  if (!level) {
    console.error('No data here!');
    return null;
  }

  if (!isPlaying && ended !== 'true' && loaded === 'true') {
    isPlaying = true;
  }

  const isSolved: boolean = isCaseSolved(cases, attr.caseId);

  useInterval(startTimer, 10);

  function startTimer() {
    const prev = sessionStorage.getItem(attr.parentTag);

    if (countdown <= 0 || isSolved) {
      // caseData?.id !== undefined) {
      if (finished) {
        return null;
      }

      setOutOfTime(checkCaseState());

      isPlaying = false;
      finished = true;
    } else {
      if (prev) {
        setCountdown(
          milliseconds - (mainCountDownTime - Number(prev.split(',')[1]))
        );
      }
    }
  }

  function checkCaseState() {
    if (countdown <= 0) {
      setCountdown(0);
      const element = document.getElementById(attr.caseId);
      if (element) element.style.display = 'unset';
      return (
        <>
          <div className="outOfTime">
            <OutOfTimeIcon />
          </div>
        </>
      );
    } else if (isSolved) {
      const element = document.getElementById(attr.caseId);
      if (element) element.style.display = 'unset';
      return (
        <>
          <div className="outOfTime">
            <SolvedIcon />
          </div>
        </>
      );
    }
    return <></>;
  }

  if (!level) {
    console.error('No data here!');
    return null;
  }

  timeState = setStateClass(level, countdown);
  return (
    <>
      {outOfTime}
      <div className={'border border-' + timeState} />
      <div className="timer_container smallTimer">
        <div className={'content content-' + timeState}>
          {getRemainingTime(level.timeInSeconds, countdown, attr.addition)}
        </div>
      </div>
    </>
  );
}

export default {
  SmallTimer,
  isCaseSolved,
  getRemainingTime,
  getTimerStyling,
  setStateClass
};
