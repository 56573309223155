// Absolute imports
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Relative imports
import { useGameDispatch, useGameState } from '../../../contexts/gameContext';
import Button from '../../../components/button/button';

// import Swiper
import 'swiper/css';
import 'swiper/css/navigation';

// Imports from same folder
import types from './types';
import NavBar from '../../../components/NavBar/NavBar';
import SmallTimer from '../../../components/smallTimer/smallTimer';
import {
  GAMECONTEXT_SETCASEDECISIONS,
  GAMECONTEXT_SETSOLVED
} from '../../../constants/globals';
import CaseDecisions from '../../../utils/models/caseDecision';
import { OutOfTimeIcon, SolvedIcon } from '../../../styling/icons/icons';
import { useInterval } from '../../../hooks/useInterval';

function Decision(attr: types) {
  const { t } = useTranslation('translations');
  const { level, cases, prevPage } = useGameState();
  const gameDispatch = useGameDispatch();

  const [optionSelected, setOptionSelected] = useState(false);
  const [feedBackText, setFeedBackText] = useState('');
  const [titleText, settitleText] = useState('');

  const prev = prevPage;

  let finished = false;

  if (!level) {
    console.error('No data here!');
    return null;
  }

  const caseData = level.cases.filter((item) => item.id === level.selectedCaseId)[0];
  let decision = '';
  let title = '';

  const mainCountDownTime = level.timeInSeconds * 1000;
  const milliseconds = Number(caseData.time * 1000);
  const [countdown, setCountdown] = useState(milliseconds);
  const [outOfTime, setOutOfTime] = useState(<></>);

  const isCaseSolved = () => {
    // get current case data it only exists if it has already been solved
    let data: CaseDecisions;
    if (cases) {
      data = cases.filter((item) => item.id === caseData.id)[0];
      return data?.id !== undefined;
    }
    return false;
  };

  function selected(e: any) {
    const value = e.target.value.split(',');
    decision = value[0];
    title = value[1];
    if (!level) {
      console.error('No data here!');
      return null;
    }

    settitleText(title);

    setFeedBackText(
      level.cases
        .filter((el) => el.id === level.selectedCaseId)[0]
        .possibilitiesOfSettlement.filter((item) => item.id === decision)[0].feedback
    );

    setOptionSelected(true);
  }

  function submit() {
    let prevList: CaseDecisions[] | undefined = cases;

    if (!level) {
      console.error('No data here!');
      return null;
    }

    if (!prevList) {
      const data = [
        new CaseDecisions({
          id: level.selectedCaseId,
          decisionId: decision,
          title: titleText,
          feedback: feedBackText
        })
      ];
      prevList = data;
    } else {
      prevList.push(
        new CaseDecisions({
          id: level.selectedCaseId.toString(),
          decisionId: decision,
          title: titleText,
          feedback: feedBackText
        })
      );
    }
    gameDispatch({
      type: GAMECONTEXT_SETCASEDECISIONS,
      payload: { caseDecisions: prevList }
    });

    checkCasesSolved();
  }

  function checkCasesSolved() {
    const prev = Number(sessionStorage.getItem('prevTime')?.split(',')[1]);
    if (!level) {
      console.error('No data here!');
      return null;
    }
    let outOfTimeAmount = 0;
    let solved = false;
    level.cases.forEach((item) => {
      const outOfTime = item.time * 1000 - (level.timeInSeconds * 1000 - prev) <= 0;
      if (!outOfTime) {
        if (!cases) {
          solved = level.cases.length === 0 + outOfTimeAmount;
        } else {
          solved = level.cases.length === cases.length + outOfTimeAmount;
        }
      } else {
        outOfTimeAmount++;
      }
    });
    gameDispatch({
      type: GAMECONTEXT_SETSOLVED,
      payload: { solved }
    });
  }

  const isSolved: boolean = isCaseSolved();

  useInterval(startTimer, 10);

  function startTimer() {
    const prev = sessionStorage.getItem('prevTime');

    if (countdown <= 0 || isSolved) {
      if (finished) return null;
      setOutOfTime(checkCaseState());
      finished = true;
    } else {
      if (prev) {
        setCountdown(
          milliseconds - (mainCountDownTime - Number(prev.split(',')[1]))
        );
      }
    }
  }

  function checkCaseState() {
    if (countdown <= 0) {
      setCountdown(0);
      const element = document.getElementById(caseData.id);
      if (element) element.style.display = 'unset';
      return (
        <>
          <div className="outOfTime">
            <OutOfTimeIcon />
          </div>
        </>
      );
    } else if (isSolved) {
      const element = document.getElementById(caseData.id);
      if (element) element.style.display = 'unset';
      return (
        <>
          <div className="outOfTime">
            <SolvedIcon />
          </div>
        </>
      );
    }
    return <></>;
  }

  return (
    <div className="body decision">
      <div className="nav">
        <NavBar
          page={attr.page}
          backButton={true}
          back={!prev ? 'workSpace' : prev}
          pauseClock={false}
          alignRight={false}
          currentPage={'decision'}
        />
      </div>
      <div className="caseInfoMonitor">
        <div className="contents">
          <div className="case-contents">
            <div className="textArea">
              <div className="left">
                <div className="suspectContent">
                  <div className="suspect">
                    <p className="bold">{t('suspect')}</p>
                    <p className="upperCase">{t(caseData.suspect.name)}</p>
                  </div>
                  <div className="MK">
                    <p className="bold">{t('MK')}</p>
                    <p className="upperCase">{t(caseData.mk)}</p>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="suspectContent">
                  <p className="bold">{t('birthDate')}</p>
                  <p>{t(caseData.suspect.date)}</p>
                </div>
              </div>
            </div>
            <div className="timer">
              <SmallTimer.SmallTimer
                seconds={caseData.time}
                size={50}
                parentTag={'prevTime'}
                saveTag={String(caseData.title) + 'Timer'}
                MainTimerAmount={level.timeInSeconds}
                addition={'(BTO)'}
                caseId={caseData.id}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pageContent">
        <div className="title">
          <h3>{t('decisionTitle')}</h3>
        </div>
        <div className="description">
          <p>{t('decisionDescription')}</p>
        </div>
        {outOfTime}
        <div className="chatContainer">
          {level?.cases
            .filter((el) => el.id === level.selectedCaseId)[0]
            .possibilitiesOfSettlement.map((item, index) => {
              return (
                <>
                  <label key={index}>
                    <input
                      type="radio"
                      name="punishments"
                      value={[item.id, item.title]}
                      onClick={selected}
                      key={index}
                    />
                    <div className="message right">
                      <div className="userMessage">
                        <p>{item.title}</p>
                      </div>
                    </div>
                  </label>
                </>
              );
            })}
        </div>

        <div className="bottomContainer bottomBuffer">
          <Button
            title={t('prevPage')}
            enviroment={!prev ? 'workSpace' : prev}
            label={t('prevPage')}
            color=""
            width="140px"
            classes="prev"
            parentFunction={attr.page}
          />
          <Button
            title={t('decisionButton')}
            enviroment="feedback"
            label={t('decisionButton')}
            color="#E65100"
            width="140px"
            classes="primary"
            parentFunction={attr.page}
            function={submit}
            disabled={!optionSelected || isSolved}
          />
        </div>
      </div>
    </div>
  );
}

export default Decision;
