// Absolute imports
import React, { useEffect } from 'react';

// Relative imports
import * as Globals from '../constants/globals';
import Level from '../utils/models/level';
import CaseDecisions from '../utils/models/caseDecision';

type Action =
  | { type: 'setLevel'; payload: any }
  | { type: 'setCaseDecisions'; payload: any }
  | { type: 'setSelectedCaseId'; payload: any }
  | { type: 'setSelectedCaseTitle'; payload: any }
  | { type: 'setSelectedParty'; payload: any }
  | { type: 'restoreGameContextState'; payload: any }
  | { type: 'setInterval'; payload: any }
  | { type: 'setSolved'; payload: any }
  | { type: 'setPrevPage'; payload: any }
  | { type: 'POL'; payload: any }
  | { type: 'HALT'; payload: any }
  | { type: 'SHN'; payload: any }
  | { type: 'RVK'; payload: any }
  | { type: 'RECL'; payload: any }
  | { type: 'setTime'; payload: any }
  | { type: 'setPauseTime'; payload: any }
  | { type: 'clearData' }
  | { type: undefined };

type Dispatch = (action: Action) => void;
interface State {
  level: Level | undefined;
  cases: CaseDecisions[] | undefined;
  intervals: NodeJS.Timer[];
  prevPage: string;
}

interface GameProviderProps {
  children: React.ReactNode;
}

const GameStateContext = React.createContext<State | undefined>(undefined);
const GameDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function gameReducer(state: State, action: Action) {
  switch (action.type) {
    case Globals.GAMECONTEXT_RESTORESTATE: {
      const newState = action.payload.state;
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }
    case Globals.GAMECONTEXT_SETLEVEL: {
      const newState = {
        ...state,
        level: action.payload.level
      };
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }

    case Globals.GAMECONTEXT_SETCASEDECISIONS: {
      const newState = {
        ...state,
        cases: action.payload.caseDecisions
      };
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }

    case Globals.GAMECONTEXT_SETINTERVAL: {
      const newState = {
        ...state,
        intervals: action.payload.intervals
      };
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }

    case Globals.GAMECONTEXT_SETPREVPAGE: {
      const newState = {
        ...state,
        prevPage: action.payload.prevPage
      };
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }

    case Globals.GAMECONTEXT_SETSOLVED: {
      const newState = {
        ...state,
        level: { ...state.level, solved: action.payload.solved }
      };
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }

    case Globals.GAMECONTEXT_SETSELECTEDCASEID: {
      const newState = {
        ...state,
        level: { ...state.level, selectedCaseId: action.payload.selectedCaseId }
      };
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }
    case Globals.GAMECONTEXT_SETSELECTEDCASETITLE: {
      const newState = {
        ...state,
        level: {
          ...state.level,
          selectedCaseTitle: action.payload.selectedCaseTitle
        }
      };
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }
    case Globals.GAMECONTEXT_TIME: {
      const newState = {
        ...state,
        level: { ...state.level, time: action.payload.time }
      };
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }
    case Globals.GAMECONTEXT_SETSELECTEDPARTY: {
      const newState = {
        ...state,
        level: { ...state.level, selectedParty: action.payload.selectedParty }
      };
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }
    case Globals.GAMECONTEXT_POLCHAT: {
      const newState = {
        ...state,
        level: { ...state.level, polChat: action.payload.polChat }
      };
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }
    case Globals.GAMECONTEXT_HALTCHAT: {
      const newState = {
        ...state,
        level: { ...state.level, haltChat: action.payload.haltChat }
      };
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }
    case Globals.GAMECONTEXT_SHNCHAT: {
      const newState = {
        ...state,
        level: { ...state.level, shnChat: action.payload.shnChat }
      };
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }
    case Globals.GAMECONTEXT_RVKCHAT: {
      const newState = {
        ...state,
        level: { ...state.level, rvkChat: action.payload.rvkChat }
      };
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }
    case Globals.GAMECONTEXT_RECLCHAT: {
      const newState = {
        ...state,
        level: { ...state.level, reclChat: action.payload.reclChat }
      };
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }
    case Globals.GAMECONTEXT_CLEAR: {
      const newState = {
        ...state,
        level: undefined,
        cases: undefined,
        intervals: undefined
      };
      sessionStorage.setItem(Globals.GAMECONTEXT_STATE, JSON.stringify(newState));
      return newState;
    }
    default: {
      throw new Error(`Unhandled action type: ${JSON.stringify(action)}`);
    }
  }
}

function GameProvider({ children }: GameProviderProps) {
  const [state, dispatch] = React.useReducer(gameReducer, {
    level: undefined,
    journals: undefined
  });

  // Hooks
  useEffect(() => {
    const storedState = sessionStorage.getItem(Globals.GAMECONTEXT_STATE);
    if (storedState) {
      dispatch({
        type: Globals.GAMECONTEXT_RESTORESTATE,
        payload: { state: JSON.parse(storedState) }
      });
    }
  }, []);

  return (
    <GameStateContext.Provider value={state}>
      <GameDispatchContext.Provider value={dispatch}>
        {children}
      </GameDispatchContext.Provider>
    </GameStateContext.Provider>
  );
}

function useGameState() {
  const context = React.useContext(GameStateContext);
  if (context === undefined) {
    throw new Error('useGameState must be used within a GameProvider');
  }
  return context;
}

function useGameDispatch() {
  const context = React.useContext(GameDispatchContext);
  if (context === undefined) {
    throw new Error('useGameDispatch must be used within a GameProvider');
  }
  return context;
}

export { GameProvider, useGameState, useGameDispatch };
