// Absolute imports
import React, { useEffect, useState } from 'react';
import { useTranslation, initReactI18next } from 'react-i18next';

// Relative imports
import { useGameDispatch, useGameState } from '../../../contexts/gameContext';

// Imports from same folder
import types from './types';
import NavBar from '../../../components/NavBar/NavBar';
import Button from '../../../components/button/button';
import LevelCase from '../../../utils/models/levelCase';

function Journals(attr: types) {
  // Hooks
  const { t } = useTranslation('translations');
  const { level } = useGameState();
  const gameDispatch = useGameDispatch();

  // Class methods
  const openJournal = (item: LevelCase) => {
    attr.page('journalDetail', item);
  };

  if (!level) {
    console.error('No data here!');
    return null;
  }

  return (
    <>
      <div className="body Monitor">
        <div className="lines" />
        <div className="nav">
          <NavBar
            page={attr.page}
            hideInstructions={false}
            backButton={true}
            pauseClock={false}
            alignRight={false}
            currentPage={'journals'}
          />
        </div>
        <div className="journal">
          <div className="container">
            <div className="title">
              <h2>{t('journalsPageTitle')}</h2>
            </div>

            <div className="display">
              {level.cases.map(function (item: any, index: number) {
                return (
                  <Button
                    label=""
                    key={index}
                    custom={
                      <label className="item">
                        <div className="journalContent">
                          <img className="journalImage" src={item.image} />
                          <div className="journalImage overlay" />
                          <div className="journalTitle">
                            <div className="alignBottom">
                              <p>{item.title}</p>
                            </div>
                          </div>
                          <div className="text">
                            <div className="suspect">
                              <p>
                                <strong>{t('suspect')}: </strong>
                                {t(item.suspect.name)}
                              </p>
                            </div>
                            <div className="suspect">
                              <p>
                                <strong>{t('MK')}: </strong>
                                {t(item.mk)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </label>
                    }
                    function={() => {
                      openJournal(item);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Journals;
