import React, { PropsWithChildren, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useDetectMobile from '../../hooks/useDetectMobile';

export default function OrientationMessage(props: PropsWithChildren) {
  const { t } = useTranslation('translations');
  const isMobile = useDetectMobile();

  useEffect(() => {
    document.body.classList.add('portrait');
    return () => {
      document.body.classList.remove('portrait');
    };
  });

  return (
    <React.Fragment>
      {props.children}
      {isMobile && (
        <div className="orientation-message-container">
          <div className="background" />

          <div className="icon-container">
            <div className="landscape-orientation-message">
              <div className="rotate-icon rotate-icon-left" />
            </div>
            <div className="portrait-orientation-message">
              <div className="rotate-icon rotate-icon-right" />
            </div>
            <h3>{t('rotatePhone')}</h3>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
