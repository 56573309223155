// Absolute imports
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Relative imports
import { useGameDispatch, useGameState } from '../../../contexts/gameContext';
import Button from '../../../components/button/button';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
// Imports from same folder
import types from './types';
import NavBar from '../../../components/NavBar/NavBar';
import RadioButton from '../../../components/radioButton/radioButton';

function WorkSpace(attr: types) {
  const { level } = useGameState();
  const { t } = useTranslation('translations');

  const [workspaceNav, setWorkspaceNav] = useState(<></>);
  const [closeButton, setCloseButton] = useState(<></>);
  const [radioSpacing, setRadioSpacing] = useState('');
  const [checked0, setChecked0] = useState<boolean | undefined>(Boolean);
  const [checked1, setChecked1] = useState<boolean | undefined>(Boolean);
  const [checked2, setChecked2] = useState<boolean | undefined>(Boolean);

  const headingBlock = () => {
    return (
      <>
        <div className="workSpace-header">
          <h1>{t('workspacePageTitle')}</h1>
          <p>{t('workspacePageDescription')}</p>
        </div>
      </>
    );
  };

  const commitButton = (
    <>
      <div className="bottomContainer bottomContainer--center bottomBuffer">
        <Button
          title={t('decisionButton')}
          enviroment={!level?.selectedCaseId ? '' : 'decision'}
          label={t('decisionButton')}
          color=""
          classes="prev"
          width="140px"
          // disabled={level?.selectedCaseId !== item.id}
          parentFunction={attr.page}
        />
      </div>
    </>
  );

  const [heading, setheading] = useState(headingBlock());
  const [caseCommit, setCaseCommit] = useState(commitButton);

  if (!level) {
    console.error('No data here!');
    return null;
  }

  function close() {
    setheading(headingBlock());
    moveMap(4);
    setChecked(false, false, false);
    setChecked(undefined, undefined, undefined);
    setCloseButton(<></>);
    setWorkspaceNav(<></>);
    setCaseCommit(commitButton);
  }

  const buttonClose = () => {
    return (
      <>
        <Button
          title={t('backToWorkSpace')}
          enviroment=""
          label={t('backToWorkSpace')}
          width="164px"
          classes="close underline"
          parentFunction={attr.page}
          function={close}
        />
      </>
    );
  };

  function select(e: any) {
    setCloseButton(
      <div className="nav-item">
        <button
          className="backButton nav-button"
          onClick={() => {
            close();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            className="arrow arrow-back"
          >
            <path d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z" />
          </svg>
        </button>
      </div>
    );
    setheading(<></>);

    setCaseCommit(<></>);

    setWorkspaceNav(
      <>
        <div className="buttonBlocker" />
        <Swiper
          modules={[Navigation]}
          initialSlide={e.target.value}
          autoHeight={false}
          direction={'horizontal'}
          slidesPerView={'auto'}
          spaceBetween={20}
          navigation={true}
          centeredSlides={true}
          onSlideChange={slideChanged}
        >
          <SwiperSlide>
            <div>
              <div className="slide">
                <img src="/images/fotos/DSC_4645-2-Enhanced-NR_monitor.jpg" />
              </div>
              <Button
                title={t('suspectMonitorPageTitle')}
                enviroment="suspectMonitor"
                label={t('suspectMonitorPageTitle')}
                width="164px"
                classes="navButton"
                parentFunction={attr.page}
                currentPage={'workSpace'}
              />
              {buttonClose()}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <div className="slide">
                <img src="/images/fotos/DSC_4676-2-Enhanced-NR.jpg" />
              </div>
              <Button
                title={t('journalsPageTitle')}
                enviroment="journals"
                label={t('journalsPageTitle')}
                width="164px"
                classes="navButton"
                parentFunction={attr.page}
              />
              {buttonClose()}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <div className="slide">
                <img src="/images/fotos/DSC_4740-2-Enhanced-NR.jpg" />
              </div>
              <Button
                title={t('roundTablePageTitle')}
                enviroment="roundTable"
                label={t('roundTablePageTitle')}
                width="164px"
                classes="navButton"
                parentFunction={attr.page}
              />
              {buttonClose()}
            </div>
          </SwiperSlide>
        </Swiper>
      </>
    );
  }

  function slideChanged(e: any) {
    moveMap(e.activeIndex);
  }

  function moveMap(index: number) {
    switch (index) {
      case 0: {
        setRadioSpacing('zeroSpacing');
        setChecked(true, undefined, undefined);
        break;
      }
      case 1: {
        setRadioSpacing('twoSpacing');
        setChecked(undefined, undefined, true);
        break;
      }
      case 2: {
        setRadioSpacing('oneSpacing');
        setChecked(undefined, true, undefined);
        break;
      }
      case 4: {
        setRadioSpacing('');
        break;
      }
    }
  }

  function setChecked(
    zero: boolean | undefined,
    one: boolean | undefined,
    two: boolean | undefined
  ) {
    setChecked0(zero);
    setChecked1(one);
    setChecked2(two);
  }

  enum Directions {
    Left = 'left',
    Right = 'right'
  }

  return (
    <div className="body">
      <div className={'map ' + radioSpacing} />
      <div className="nav">
        {closeButton}
        <NavBar
          page={attr.page}
          backButton={false}
          pauseClock={false}
          alignRight={false}
          currentPage={'workSpace'}
        />
      </div>
      <div className="workSpace">
        {heading}
        <div className="content">
          <div className="radioButtons">
            <div className={'buttonContainer ' + radioSpacing}>
              <RadioButton
                contentSide={Directions.Right}
                Value={0}
                radioName="verdachten"
                isChecked={checked0}
                onClick={select}
                onChange={() => {
                  moveMap(0);
                }}
                Title={t('suspectMonitorPageTitle')}
                Content={t('suspectMonitorPageDescription')}
              />
              <br />
              <RadioButton
                contentSide={Directions.Left}
                Value={1}
                radioName="journal"
                isChecked={checked2}
                onClick={select}
                onChange={() => {
                  moveMap(2);
                }}
                Title={t('journalsPageTitle')}
                Content={t('journalsPageDesription')}
              />

              <br />
              <RadioButton
                contentSide={Directions.Right}
                Value={2}
                radioName="tafel"
                isChecked={checked1}
                onClick={select}
                onChange={() => {
                  moveMap(1);
                }}
                Title={t('roundTablePageTitle')}
                Content={t('roundTablePageDescription')}
              />
            </div>
          </div>
        </div>
        {workspaceNav}
      </div>
      {caseCommit}
    </div>
  );
}

export default WorkSpace;
