// Absolute imports

// Relative imports
import BaseParser from './parser/BaseParser';

interface ICaseDecisions {
  id: string;
  decisionId: string;
  title: string;
  feedback: string;
}

export default class CaseDecisions extends BaseParser implements ICaseDecisions {
  id = '';
  decisionId = '';
  title = '';
  feedback = '';

  constructor(attr: ICaseDecisions) {
    super();
    this.id = attr.id;
    this.decisionId = attr.decisionId;
    this.title = attr.title;
    this.feedback = attr.feedback;
  }
}
