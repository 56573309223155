import React from 'react';
import types from './types';
import { title } from 'process';

function RadioButton(attr: types) {
  const titlePos = attr.isChecked ? `radioText-${attr.contentSide}--open` : '';

  const right = (
    <>
      <div className="icon">
        <input
          type="radio"
          onClick={attr.onClick}
          value={attr.Value}
          checked={attr.isChecked}
          onChange={attr.onChange}
          className="radioInput"
        />
        <div className="dot" />
      </div>
      <div className="radioTextBlock">
        <p className={`radioText radioText-${attr.contentSide} ${titlePos}`}>
          {attr.Title}
        </p>
      </div>
    </>
  );

  const left = (
    <>
      <div className="radioTextBlock">
        <p className={`radioText radioText-${attr.contentSide} ${titlePos}`}>
          {attr.Title}
        </p>
      </div>
      <div className="icon">
        <input
          type="radio"
          onClick={attr.onClick}
          value={attr.Value}
          checked={attr.isChecked}
          onChange={attr.onChange}
          className="radioInput"
        />
        <div className="dot" />
      </div>
    </>
  );
  return (
    <>
      <label className={`${attr.radioName}-radio`}>
        <div className="radio">{attr.contentSide === 'right' ? right : left}</div>
        <div>
          <p className="description">{attr.isChecked ? attr.Content : ''}</p>
        </div>
      </label>
    </>
  );
}

export default RadioButton;
