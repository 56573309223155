// Absolute imports
import React, { useEffect, useState } from 'react';

// Relative imports
import { useTranslation, initReactI18next } from 'react-i18next';
import types from './types';
import Button from '../../../components/button/button';

function Home(attr: types) {
  // Hooks
  const { t } = useTranslation('translations');

  return (
    <div className="page page-start introduction">
      <div className="dots" />
      <div className="topContainerStart">
        <div className="topContent">
          <div className="startPageImage">
            <div className="cover" />
          </div>
        </div>
        <div className="contentContainer">
          <div className="textContainer">
            <div className="heading">
              <h1 className="startTitle">{t('startPageTitle')}</h1>
              <p>{t('startPagesubTitle')}</p>
            </div>
            <p className="content">{t('startPageDescription')}</p>
          </div>
        </div>
      </div>
      <div className="bottomContainer bottomContainer--center bottomBuffer">
        <Button
          title={t('startPageButtonTitle')}
          enviroment="roleOfOfficer"
          label={t('nextPage')}
          color="#E65100"
          classes="orange"
          width="140px"
          parentFunction={attr.page}
        />
      </div>
    </div>
  );
}

export default Home;
