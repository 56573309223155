// Absolute imports
import React, { useEffect, useState } from 'react';
import { useTranslation, initReactI18next } from 'react-i18next';

// Relative imports
import { useGameDispatch, useGameState } from '../../../contexts/gameContext';

// Imports from same folder
import types from './types';
import NavBar from '../../../components/NavBar/NavBar';
import Button from '../../../components/button/button';
import { GAMECONTEXT_CLEAR } from '../../../constants/globals';

function Endpage(attr: types) {
  // Hooks
  const { t } = useTranslation('translations');
  const { level } = useGameState();
  const gameDispatch = useGameDispatch();

  if (!level) {
    console.error('No data here!');
    return null;
  }

  const clearSessionData = () => {
    gameDispatch({
      type: GAMECONTEXT_CLEAR
    });
    sessionStorage.removeItem('sessionEnded');
    sessionStorage.removeItem('prevTime');
    sessionStorage.removeItem('levelLoaded');
  };

  return (
    <>
      <div className="body endPage">
        <div className="lines" />
        <div className="nav">
          <NavBar
            page={attr.page}
            hideInstructions={false}
            backButton={false}
            back={'workSpace'}
            pauseClock={false}
            alignRight={false}
            timerVisible={false}
            currentPage={'endPage'}
          />
        </div>
        <div className="container">
          <div className="title">
            <h2>{t('endPageTitle')}</h2>
          </div>
          <div className="textContent">
            <p>{t('endPageDescription')}</p>
          </div>
        </div>
        <div className="bottomContainer bottomBuffer">
          <Button
            title={t('backToStart')}
            enviroment="startPage"
            label={t('backToStart')}
            color="#E65100"
            width="140px"
            classes="primary"
            parentFunction={attr.page}
            function={clearSessionData}
          />
        </div>
      </div>
    </>
  );
}

export default Endpage;
