// Absolute imports

// Relative imports
import BaseParser from './parser/BaseParser';

interface ISuspect {
  name: string;
  date: string;
  address: string;
  nationality: string;
}

export default class Suspect extends BaseParser implements ISuspect {
  name = '';
  date = '';
  address = '';
  nationality = '';

  static parseList(data: any) {
    const items = [];
    for (const i in data) {
      items.push(this.parse(data[i]));
    }
    return items;
  }

  static parse(object: any) {
    const susp = new Suspect();
    susp.name = this.parseString(object.suspectName);
    susp.date = this.parseString(object.dateOfBirth);
    susp.address = this.parseString(object.address);
    susp.nationality = this.parseString(object.nationality);

    return susp;
  }
}
