import React from 'react';
// Relative imports
import { useTranslation } from 'react-i18next';
import types from './types';
// Imports from same folder
import NavBar from '../../../components/NavBar/NavBar';
import { MenuIcon, ProfileIcon } from '../../../styling/icons/icons';
import { useGameState } from '../../../contexts/gameContext';

function Instruction(attr: types) {
  // Hooks
  const { t } = useTranslation('translations');
  const { prevPage } = useGameState();
  const prev = prevPage;
  console.log(prevPage);
  return (
    <div className="body">
      <div className="nav">
        <NavBar
          page={attr.page}
          hideInstructions={true}
          backButton={true}
          back={prevPage}
          pauseClock={true}
          alignRight={false}
          currentPage={'instruction'}
        />
      </div>

      <div className="instructions">
        {/* <div className="dots" /> */}
        <div className="bottomContent">
          <div className="container">
            <h2>{t('instructionPageTitle')}</h2>
            <div className="explanationPage">
              <div className="text">
                <p className="blue">{t('exampleTitle01')} &nbsp;</p>
                <p>{t('exampleDescription01')}</p>
              </div>
              <div className="nav-item nav-buttonIcon">
                <div>
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="arrow"
                    width="34px"
                    height="34px"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M6 12H18M6 12L11 7M6 12L11 17"
                        stroke="#000000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="explanationPage">
              <div className="text noFlex">
                <p className="blue">{t('exampleTitle02')} &nbsp;</p>
                <p>{t('exampleDescription02')}</p>
              </div>
              <div className="nav-menuIcon">
                <div>
                  <MenuIcon />
                </div>
              </div>
            </div>
            <div className="explanationPage">
              <div className="text noFlex">
                <p className="blue">{t('exampleTitle03')} &nbsp;</p>
                <p>{t('exampleDescription03')}</p>
              </div>
              <div className="nav-menuIcon">
                <div>
                  <ProfileIcon />
                </div>
              </div>
            </div>
            <div className="explanationPage">
              <div className="text noFlex">
                <p className="blue">{t('exampleTitle04')} &nbsp;</p>
                <p>{t('exampleDescription04')}</p>
              </div>
              <div className="timer_container right">
                <div className="content">
                  <p>05</p>
                  <p className="small">uur &nbsp;</p>
                  <p>45</p>
                  <p className="small">min</p>
                </div>
              </div>
            </div>
            <div className="explanationPage">
              <div className="text noFlex">
                <p className="blue">{t('exampleTitle05')} &nbsp;</p>
                <p>{t('exampleDescription05')}</p>
              </div>
            </div>
            <div className="explanationPage">
              <div className="text noFlex">
                <p className="blue">{t('exampleTitle06')} &nbsp;</p>
                <p>{t('exampleDescription06')}</p>
              </div>
            </div>
            <div className="explanationPage">
              <div className="text noFlex">
                <p className="blue">{t('exampleTitle07')} &nbsp;</p>
                <p>{t('exampleDescription07')}</p>
              </div>
            </div>
            <div className="explanationPage">
              <div className="text noFlex">
                <p className="blue">{t('exampleTitle08')} &nbsp;</p>
                <p>{t('exampleDescription08')}</p>
              </div>
            </div>
            <div className="explanationPage">
              <div className="text noFlex">
                <p className="blue">{t('exampleTitle09')} &nbsp;</p>
                <p>{t('exampleDescription09')}</p>
              </div>
            </div>
            <div className="explanationPage">
              <div className="text noFlex">
                <p className="blue">{t('exampleTitle10')} &nbsp;</p>
                <p>{t('exampleDescription10')}</p>
              </div>
            </div>
            <div className="explanationPage">
              <div className="text noFlex">
                <p className="blue">{t('exampleTitle11')} &nbsp;</p>
                <p>{t('exampleDescription11')}</p>
              </div>
            </div>
            <div className="explanationPage explanationMargin">
              <div className="text noFlex">
                <p className="blue">{t('exampleTitle12')} &nbsp;</p>
                <p>{t('exampleDescription12')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Instruction;
