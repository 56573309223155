// Absolute imports
import i18next from 'i18next';

// Relative imports
import api from '../Api';

const translationService = {
  getLanguages: async (params: any, responseCallBack: any, errorCallBack: any) => {
    const response = await api.create().get('translations/languages.json', params);

    if (response.success) {
      responseCallBack(response.data);
    } else {
      errorCallBack(response);
    }
  },

  getLanguage: async (
    langId: string,
    fileName: string,
    responseCallBack: any,
    errorCallBack: any
  ) => {
    const response = await api.create().get('translations/' + fileName, {});

    if (response.success) {
      i18next.addResourceBundle('nl', 'translations', response.data, true, true);
      responseCallBack(response.data);
    } else {
      errorCallBack(response);
    }
  }
};

export default translationService;
