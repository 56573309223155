// Absolute imports

// Relative imports

// Imports from same folder

class BaseParser {
  static parseString(value: any, removeBreaks = false) {
    if (value) {
      if (!removeBreaks) {
        return value.toString().trim();
      } else {
        return value.toString().replace('\r\n', '').trim();
      }
    }
    return '';
  }

  static parseNumber(value: any) {
    return Number(value);
  }

  static parseDate(value: any) {
    return Date.parse(value);
  }

  static parseBool(value: any) {
    return Boolean(value);
  }
}

export default BaseParser;
