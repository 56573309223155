// Absolute imports

// Relative imports
import Chat from './chat';
import BaseParser from './parser/BaseParser';

interface ICounterParty {
  id: string;
  title: string;
  description: string;
  chat: Chat[];
}

export default class CounterParty extends BaseParser implements ICounterParty {
  id = '';
  title = '';
  description = '';
  chat = Array<Chat>();

  static parseList(data: any): CounterParty[] {
    const items = [];
    for (const i in data) {
      items.push(this.parse(data[i]));
    }
    return items;
  }

  static parse(object: any) {
    const counterParties = new CounterParty();
    counterParties.id = this.parseString(object.counterPartyId);
    counterParties.title = this.parseString(object.title);
    counterParties.description = this.parseString(object.description);
    counterParties.chat = Chat.parseList(object.chat);

    return counterParties;
  }
}
