// Absolute imports

// Relative imports
import BaseParser from './parser/BaseParser';

interface IChat {
  id: string;
  question: string;
  answer: string;
  subQuestions: Chat[];
  answered: boolean;
  delay: number;
}

export default class Chat extends BaseParser implements IChat {
  id = '';
  question = '';
  answer = '';
  subQuestions = Array<Chat>();
  answered = false;
  delay = 0;

  static parseList(data: any) {
    const items = [];
    for (const i in data) {
      items.push(this.parse(data[i]));
    }
    return items;
  }

  static parse(object: any) {
    const chat = new Chat();
    chat.id = this.parseString(object.questionId);
    chat.question = this.parseString(object.question);
    chat.answer = this.parseString(object.answer);
    chat.subQuestions = this.parseList(object.subquestions);
    chat.delay = this.parseNumber(object.answerDelay);

    return chat;
  }
}
