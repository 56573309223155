// Absolute imports
import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { Helmet } from 'react-helmet';

// Relative imports
import DynamicComponent from '../components/dynamicRenderer/dynamicComponent';
import translationService from '../utils/services/dataServices/translationService';
import OrientationMessage from '../components/OrientationMessage/OrientationMessage';

// Imports from same folder
function App() {
  const [isLoading, setIsLoading] = useState(true);

  // Effects
  useEffect(() => {
    const fetchData = async () => {
      await translationService.getLanguage(
        'nl',
        'nl-NL.json',
        async (response: any) => {
          setIsLoading(false);
        },
        (error: any) => {
          console.log(error);
        }
      );
    };

    fetchData().catch(console.error);
  }, []);

  // Render
  return (
    <OrientationMessage>
      {isLoading && (
        <div className="spinner">
          <TailSpin
            height="80"
            width="80"
            color="#01689b"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={isLoading}
          />
        </div>
      )}
      {!isLoading && (
        <>
          <Helmet>
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="mobile-web-app-capable" content="yes" />
          </Helmet>
          <DynamicComponent />
        </>
      )}
    </OrientationMessage>
  );
}

export default App;
