import React, { useState } from 'react';
import { useGameDispatch, useGameState } from '../../contexts/gameContext';
import {
  ArrowBack,
  CloseIcon,
  FeetIcon,
  LogOut,
  MenuIcon,
  ProfileIcon
} from '../../styling/icons/icons';
import Button from '../button/button';
import CountdownTimer from '../timer/timer';
import * as Globals from '../../constants/globals';
import types from './types';
import { useTranslation } from 'react-i18next';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import CaseInfo from '../caseInfo/caseInfo';

function NavBar(attr: types) {
  const { level } = useGameState();
  const gameDispatch = useGameDispatch();
  const [menu, setMenu] = useState('none');
  const [menuSpacing, setMenuSpacing] = useState('');
  const [hideButton, setHideButton] = useState('');
  const [notification, setNotification] = useState(<></>);
  const [menuActive, setMenuActive] = useState(false);
  const [quitColor, setQuitColor] = useState('');
  const { t } = useTranslation('translations');
  const [caseInfo, setCaseInfo] = useState(<></>);
  const [displayInfo, setDisplayInfo] = useState(false);

  if (!level) {
    console.error('No data here!');
    return null;
  }

  const [clock, setClock] = useState(
    <CountdownTimer
      seconds={level.timeInSeconds}
      size={100}
      strokeBgColor={'white'}
      strokeColor={'lightgreen'}
      strokeWidth={6}
      saveTag={'prevTime'}
      classes={'clockContainer'}
      paused={attr.pauseClock}
      page={attr.page}
    />
  );
  const clearSessionData = () => {
    gameDispatch({
      type: Globals.GAMECONTEXT_CLEAR
    });
    sessionStorage.removeItem('sessionEnded');
    sessionStorage.removeItem('prevTime');
    sessionStorage.removeItem('levelLoaded');
  };

  function destroyPopUp() {
    setNotification(<></>);
    setMenuSpacing('');
    setHideButton('');
    setQuitColor('');
  }

  function popUp() {
    setQuitColor('clicked');
    setMenuSpacing('popUpSpacing');
    setHideButton('hideButton');
    setNotification(
      <div className="notification">
        <div className="content">
          <h3>{t('quitTitle')}</h3>
          <p>{t('quitText')}</p>
          <div className="buttons">
            <Button
              title={t('cancel')}
              label={t('cancel')}
              width="140px"
              classes="prev spacing"
              function={destroyPopUp}
            />
            <Button
              title={t('quit')}
              enviroment="startPage"
              label={t('quit')}
              color="#E65100"
              width="140px"
              classes="orange"
              parentFunction={attr.page}
              function={clearSessionData}
            />
          </div>
        </div>
      </div>
    );
  }

  function destroyMenu() {
    if (!level) {
      console.error('No data here!');
      return null;
    }

    setClock(
      <CountdownTimer
        seconds={level.timeInSeconds}
        size={100}
        strokeBgColor={'white'}
        strokeColor={'lightgreen'}
        strokeWidth={6}
        saveTag={'prevTime'}
        classes={'clockContainer'}
        paused={attr.pauseClock}
        page={attr.page}
      />
    );
    setMenu('none');
    setMenuActive(false);
  }

  function createMenu() {
    if (!level) {
      console.error('No data here!');
      return null;
    }
    setClock(
      <CountdownTimer
        seconds={level.timeInSeconds}
        size={100}
        strokeBgColor={'white'}
        strokeColor={'lightgreen'}
        strokeWidth={6}
        saveTag={'prevTime'}
        classes={'clockContainer'}
        paused={true}
        page={attr.page}
      />
    );

    setMenu('unset');
    setMenuActive(true);
  }

  const instructions = (
    <>
      <div className={'item top ' + hideButton}>
        <FeetIcon />
        <Button
          title={t('toExplanation')}
          enviroment="instruction"
          label={t('toExplanation')}
          parentFunction={attr.page}
          currentPage={attr.currentPage}
          // TODO clearData And implement a are you sure message
        />
      </div>
      <div className={'divider ' + hideButton} />
    </>
  );

  const backButton = (
    <div className="nav-item nav-button backButton">
      <Button
        enviroment={attr.back ?? 'workSpace'}
        custom={<ArrowBack />}
        label={t('back')}
        color="transparent"
        classes=""
        parentFunction={attr.page}
        currentPage={attr.currentPage}
      />
    </div>
  );

  function showCaseInfo() {
    const selectedCase = level?.cases.filter(
      (item) => item.id === level.selectedCaseId
    )[0];

    if (!level || !selectedCase) {
      console.error('No data here!');
      return null;
    }

    setCaseInfo(
      <CaseInfo selectedCase={selectedCase} alignRight={attr.alignRight} />
    );
    setDisplayInfo(true);
  }

  function hideCaseInfo() {
    setCaseInfo(<></>);
    setDisplayInfo(false);
  }

  const ref = useOutsideClick(hideCaseInfo);

  const disable = !level.selectedCaseId ? 'profile-disable' : '';
  const alignRight = attr.alignRight ? 'clockRight' : '';
  return (
    <>
      {attr.backButton ? backButton : <></>}
      {attr.pageTitle && attr.pageContext ? (
        <div className={'nav-item pageTitle'}>
          <p className="title">{t(attr.pageTitle)}</p>
          <div className="pageContext">
            <p>{t(attr.pageContext)}</p>
          </div>
        </div>
      ) : (
        <></>
      )}

      {attr.timerVisible !== false ? (
        <div className={'nav-item clockContainer ' + alignRight}>
          <div className={'profile ' + disable}>
            <button
              ref={ref}
              onClick={displayInfo ? hideCaseInfo : showCaseInfo}
              disabled={!level?.selectedCaseId}
            >
              <ProfileIcon />
            </button>
          </div>
          {clock}
        </div>
      ) : (
        <></>
      )}
      <div className="nav-menu">
        <button onClick={!menuActive ? createMenu : destroyMenu}>
          {!menuActive ? <MenuIcon /> : <CloseIcon />}
        </button>
      </div>
      <div className="menu" style={{ display: menu }}>
        <div className={'menuContainer ' + menuSpacing}>
          {!attr.hideInstructions ? instructions : <></>}
          <div className={'item bottem ' + quitColor}>
            <LogOut />
            <Button
              title={t('quit')}
              label={t('quit')}
              classes={''}
              function={popUp}
              // TODO clearData And implement a are you sure message
            />
          </div>
        </div>
        {notification}
      </div>
      {caseInfo}
    </>
  );
}

export default NavBar;
