// Absolute imports
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Relative imports
import SmallTimer from '../../../components/smallTimer/smallTimer';
import * as Globals from '../../../constants/globals';
import NavBar from '../../../components/NavBar/NavBar';

// Imports from same folder
import { useGameState, useGameDispatch } from '../../../contexts/gameContext';
import types from './types';

function SuspectMonitor(attr: types) {
  // Hooks
  const { t } = useTranslation('translations');
  const { level } = useGameState();
  const gameDispatch = useGameDispatch();

  // Class methods
  const radioChanged = (id: string, title: string) => {
    gameDispatch({
      type: Globals.GAMECONTEXT_SETSELECTEDCASEID,
      payload: { selectedCaseId: id }
    });
    gameDispatch({
      type: Globals.GAMECONTEXT_SETSELECTEDCASETITLE,
      payload: { selectedCaseTitle: title }
    });
  };

  useEffect(() => {
    document.body.classList.add('landscape');
    document.body.classList.remove('portrait');
    return () => {
      document.body.classList.remove('landscape');
      document.body.classList.add('portrait');
    };
  });

  // Render
  if (!level) {
    console.error('No data here!');
    return null;
  }

  const getPriority = (index: number) => {
    switch (index) {
      case 1: {
        return 'box-green';
      }
      case 2: {
        return 'box-yellow';
      }
      case 3: {
        return 'box-gray';
      }
      default: {
        return '';
      }
    }
  };

  return (
    <div className="body Monitor">
      <div className="nav">
        <NavBar
          page={attr.page}
          backButton={true}
          pauseClock={false}
          alignRight={true}
          currentPage={'suspectMonitor'}
          pageTitle="suspectMonitorPageTitle"
          pageContext="suspectMonitorPageContext"
        />
      </div>
      <div className="screen">
        <div className="screenGlare" />
        <div className="display">
          {level.cases.map(function (item: any, index: number) {
            return (
              <>
                <div
                  style={{ display: 'none' }}
                  className="selectable"
                  id={item.id}
                />
                <label key={index}>
                  <input
                    type="radio"
                    value={item.id}
                    name="case"
                    onChange={() => {
                      radioChanged(item.id, item.title);
                    }}
                    defaultChecked={level.selectedCaseId === item.id}
                  />
                  <div key={index} className="caseInfoMonitor">
                    <div className="contents">
                      <div className="case-contents">
                        <div className="textArea">
                          <div className="left">
                            <div className="suspectContent">
                              <div className="suspect">
                                <p className="bold">{t('suspect')}</p>
                                <p className="upperCase">{t(item.suspect.name)}</p>
                              </div>
                              <div className="MK">
                                <p className="bold">{t('MK')}</p>
                                <p className="upperCase">{t(item.mk)}</p>
                              </div>
                            </div>
                          </div>
                          <div className="right">
                            <div className="suspectContent">
                              <p className="bold">{t('birthDate')}</p>
                              <p>{t(item.suspect.date)}</p>
                            </div>
                          </div>
                        </div>
                        <div className="timer">
                          <SmallTimer.SmallTimer
                            seconds={item.time}
                            size={50}
                            parentTag={'prevTime'}
                            saveTag={String(item.title) + 'Timer'}
                            MainTimerAmount={level.timeInSeconds}
                            addition={'(BTO)'}
                            caseId={item.id}
                          />
                        </div>
                      </div>
                      <div className="case-priorities">
                        <div className="prioritieContainer">
                          <div className="item">
                            <p className="text">recl</p>
                            <div
                              className={'box ' + getPriority(item.priorities.recl)}
                            />
                          </div>
                          <div className="item">
                            <p className="text">rvk</p>
                            <div
                              className={'box ' + getPriority(item.priorities.rvk)}
                            />
                          </div>
                          <div className="item">
                            <p className="text">shn</p>
                            <div
                              className={'box ' + getPriority(item.priorities.shn)}
                            />
                          </div>
                          <div className="item">
                            <p className="text">halt</p>
                            <div
                              className={'box ' + getPriority(item.priorities.halt)}
                            />
                          </div>
                          <div className="item">
                            <p className="text">pol</p>
                            <div
                              className={'box ' + getPriority(item.priorities.pol)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              </>
            );
          })}

          <div className="bottomSpacing" />
        </div>
      </div>
    </div>
  );
}

export default SuspectMonitor;
