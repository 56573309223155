// Absolute imports

// import { stringify } from 'query-string'
// import {  Platform } from 'react-native'
// import Constants from 'expo-constants'

// Relative imports
import {
  IApi,
  IApiSettings,
  TApiMethod,
  IApiQuery,
  IApiBody,
  IApiResponse,
  IApiInstance
} from '../interfaces/Api';

const defaultSettings: IApiSettings = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  },
  method: 'GET'
};

const serializeJSON = (data: IApiBody) => {
  return Object.keys(data)
    .map(
      (keyName: string) =>
        encodeURIComponent(keyName) + '=' + encodeURIComponent(data[keyName])
    )
    .join('&');
};

const api: IApi = {
  request: async (
    method: TApiMethod,
    endpoint: string,
    query?: IApiQuery,
    body?: IApiBody,
    settings?: IApiSettings,
    formurlencoded?: boolean
  ): Promise<IApiResponse> => {
    let url = `${String(process.env.REACT_APP_API_URL)}${endpoint}`;
    const requestSettings: IApiSettings = {
      ...defaultSettings,
      method
    };

    if (formurlencoded) {
      requestSettings.headers = {
        ...requestSettings.headers,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    }

    if (endpoint.indexOf('http') > -1) {
      url = endpoint;
    }

    // if (query) {
    //     url += '?' + stringify(query)
    // }

    if (body) {
      if (formurlencoded) {
        requestSettings.body = serializeJSON(body);
      } else {
        // requestSettings.body = JSON.stringify(body);
      }
    }

    if (settings) {
      if (settings.headers) {
        requestSettings.headers = {
          ...requestSettings.headers,
          ...settings.headers
        };
      }
    }

    try {
      const response = await fetch(url, {
        method: requestSettings.method,
        headers: requestSettings.headers,
        body: requestSettings.body
      });

      let responseData: any;
      if (!response.ok) {
        try {
          if ('json' in response) {
            responseData = await response.json();
          }
          return {
            success: false,
            // status: response.status,
            data: responseData
          };
        } catch (e) {
          return {
            success: false,
            message: '',
            // status: response.status,
            data: responseData
          };
        }
      }
      try {
        if ('json' in response) {
          responseData = await response.json();
        }
        return {
          success: true,
          data: responseData
        };
      } catch (e) {
        return {
          success: true,
          data: responseData
        };
      }
    } catch (e) {
      return {
        success: false
        // networkrequestfailed: e.message === 'Network request failed',
        // message: e.message
      };
    }
  },
  create: (settings: IApiSettings = defaultSettings): IApiInstance => ({
    settings,
    async request(
      method: TApiMethod,
      endpoint: string,
      query?: IApiQuery,
      body?: IApiBody,
      settings?: IApiSettings,
      formurlencoded?: boolean
    ) {
      return await api.request(
        method,
        endpoint,
        query,
        body,
        settings,
        formurlencoded
      );
    },
    async get(endpoint: string, query?: IApiQuery, settings?: IApiSettings) {
      return await this.request('GET', endpoint, query, undefined, settings);
    },
    async post(endpoint: string, body: IApiBody, settings?: IApiSettings) {
      return await this.request('POST', endpoint, undefined, body, settings);
    },
    async postformencoded(
      endpoint: string,
      body: IApiBody,
      settings?: IApiSettings
    ) {
      return await this.request('POST', endpoint, undefined, body, settings, true);
    },
    async put(endpoint: string, body: IApiBody, settings?: IApiSettings) {
      return await this.request('PUT', endpoint, undefined, body, settings);
    },
    async delete(
      endpoint: string,
      query?: IApiQuery,
      body?: IApiBody,
      settings?: IApiSettings
    ) {
      return await this.request('DELETE', endpoint, query, body, settings);
    }
  })
};

export default api;
