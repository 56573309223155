// Absolute imports

// Relative imports
import CounterParty from './counterParty';
import BaseParser from './parser/BaseParser';
import Priorities from './priorities';
import Suspect from './suspect';
import Settlement from './settlement';

interface ILevelCase {
  id: string;
  title: string;
  description: string;
  date: string;
  dateTime: string;
  location: string;
  time: number;
  mk: string;
  image: string;
  counterParties: CounterParty[];
  suspect: Suspect;
  priorities: Priorities;
  outOfTime: boolean;
  beslag: boolean;
  reportMoney: boolean;
  possibilitiesOfSettlement: Settlement[];
}

export default class LevelCase extends BaseParser implements ILevelCase {
  id = '';
  title = '';
  description = '';
  date = '';
  dateTime = '';
  location = '';
  time = 0;
  mk = '';
  image = '';
  counterParties = Array<CounterParty>();
  suspect = new Suspect();
  priorities = new Priorities();
  beslag = false;
  reportMoney = false;
  outOfTime = false;
  possibilitiesOfSettlement = Array<Settlement>();

  static parseList(data: any): LevelCase[] {
    const items = [];
    for (const i in data) {
      items.push(this.parse(data[i]));
    }
    return items;
  }

  static parse(object: any) {
    const levelCase = new LevelCase();
    levelCase.id = this.parseString(object.caseId);
    levelCase.title = this.parseString(object.caseTitle);
    levelCase.description = this.parseString(object.caseDescription);
    levelCase.date = this.parseString(object.caseDate);
    levelCase.dateTime = this.parseString(object.caseDateTime);
    levelCase.location = this.parseString(object.caseLocationTitle);
    levelCase.time = this.parseNumber(object.caseTime);
    levelCase.image = this.parseString(object.caseImage);
    levelCase.beslag = this.parseBool(object.caseBeslag);
    levelCase.reportMoney = this.parseBool(object.caseReportMoney);

    levelCase.mk = this.parseString(object.MK);
    levelCase.counterParties = CounterParty.parseList(object.counterParties);
    levelCase.suspect = Suspect.parse(object.suspect);
    levelCase.priorities = Priorities.parse(object.priorities);
    levelCase.possibilitiesOfSettlement = Settlement.parseList(
      object.possibilitiesOfSettlement
    );

    return levelCase;
  }
}
