// Absolute imports
import React, { useEffect, useState } from 'react';
import { useTranslation, initReactI18next } from 'react-i18next';

// Relative imports
import { useGameDispatch, useGameState } from '../../../contexts/gameContext';
import NavBar from '../../../components/NavBar/NavBar';
import ReactMarkdown from 'react-markdown';

// Imports from same folder
import types from './types';
import Button from '../../../components/button/button';

function JournalDetail(attr: types) {
  // Hooks
  const { t } = useTranslation('translations');
  const { level } = useGameState();
  const { item } = attr;
  if (!item) {
    console.error('No data here!');
    return null;
  }

  const time = item.dateTime;
  return (
    <>
      <div className="body Detail">
        <div className="nav">
          <NavBar
            page={attr.page}
            hideInstructions={false}
            backButton={true}
            back={'journals'}
            pauseClock={false}
            alignRight={false}
            currentPage={'journalDetail'}
          />
        </div>
        <div className="dotsOverlay">
          <div className="dots" />
          <div className="title">
            <p>{item.title}</p>
          </div>
          <img className="journalImage" src={item.image} />
          <div className="suspectData">
            <div className="left">
              <div className="item">
                <p className="bold">{t('suspect')}</p>
                <p>{item.suspect.name}</p>
              </div>
              <div className="item">
                <p className="bold">{t('bornOn')}</p>
                <p>{item.suspect.date}</p>
              </div>
              <div className="item">
                <p className="bold">{t('livingAdress')}</p>
                <p>{item.suspect.address}</p>
              </div>
              <div className="item">
                <p className="bold">{t('nationality')}</p>
                <p>{item.suspect.nationality}</p>
              </div>

              <div className="item">
                <p className="bold">{t('MK')}</p>
                <p>{item.mk}</p>
              </div>
            </div>
            <div className="right">
              <div className="item">
                <p className="bold">{t('date')}</p>
                <p>{item.date}</p>
              </div>
              <div className="item">
                <p className="bold">{t('time')}</p>
                <p>{time}</p>
              </div>
              <div className="item">
                <p className="bold">{t('location')}</p>
                <p>{item.location}</p>
              </div>
              <div className="item">
                <p className="bold">{t('beslag')}</p>
                <p> {item.beslag ? t('yes') : t('no')}</p>
              </div>
              <div className="item">
                <p className="bold">{t('reportMoney')}</p>
                <p> {item.reportMoney ? t('yes') : t('no')}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="paperBackground">
          <div className="paper">
            <div className="context">
              <p>{t('contextInformation')}</p>
              <ReactMarkdown>{item.description}</ReactMarkdown>
            </div>
            {/* <div className="caseStatus">
              <p>{t('caseStatus')}</p>
              <p>{decisionTaken}</p>
              <p>{'ALS CHAT:' + chat}</p>
              <p>{t('Politie heeft aanvullende informatie na tweede verhoor.')}</p>
            </div> */}
            <div className="paperButton">
              <div className="bottomContainer bottomBuffer">
                <Button
                  title={t('prevPage')}
                  enviroment="journals"
                  label={t('prevPage')}
                  width="140px"
                  classes="prev"
                  parentFunction={attr.page}
                />
                <Button
                  title={t('decisionButton')}
                  enviroment={!level?.selectedCaseId ? '' : 'decision'}
                  label={t('decisionButton')}
                  color="#E65100"
                  width="140px"
                  classes="primary"
                  parentFunction={attr.page}
                  disabled={level?.selectedCaseId !== item.id}
                  currentPage="journals"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JournalDetail;
