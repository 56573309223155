// Absolute imports

// Relative imports
import LevelCase from './levelCase';
import BaseParser from './parser/BaseParser';

interface ILevel {
  timeInSeconds: number;
  timeTransition: number;
  pauseTime: boolean;
  cases: LevelCase[];
  selectedCaseId: string;
  selectedCaseTitle: string;
  selectedParty: string;

  solved: boolean;

  // chat history
  polChat: chatHistory;
  haltChat: chatHistory;
  shnChat: chatHistory;
  rvkChat: chatHistory;
  reclChat: chatHistory;
}

interface chatHistory {
  [key: string]: Array<{ class: string; text: string }>;
}

export default class Level extends BaseParser implements ILevel {
  pauseTime = false;
  timeInSeconds = 0;
  timeTransition = 0;
  cases = Array<LevelCase>();
  selectedCaseId = '';
  selectedCaseTitle = '';
  selectedParty = '';

  solved = false;

  // chat history
  polChat: chatHistory = {};
  haltChat: chatHistory = {};
  shnChat: chatHistory = {};
  rvkChat: chatHistory = {};
  reclChat: chatHistory = {};

  static parseList(data: any): Level[] {
    const items = [];
    for (const i in data) {
      items.push(this.parse(data[i]));
    }
    return items;
  }

  static parse(object: any) {
    const level = new Level();
    level.timeInSeconds = this.parseNumber(object.timeInSeconds);
    level.timeTransition = this.parseNumber(object.timeTransition);
    level.cases = LevelCase.parseList(object.cases);
    return level;
  }
}
export type { chatHistory };
