// inspired by: https://stackoverflow.com/questions/72502079/how-can-i-check-if-the-device-which-is-using-my-website-is-a-mobile-user-or-no

import { useLayoutEffect, useState } from 'react';

export default function useDetectMobile() {
  const [isMobile, setIsMobile] = useState(
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  );
  useLayoutEffect(() => {
    // Screen resolution method
    if (!isMobile) {
      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      setIsMobile(screenWidth < 768 || screenHeight < 768);
    }

    // Touch events method
    if (!isMobile) {
      setIsMobile('ontouchstart' in window || navigator.maxTouchPoints > 0);
    }

    // CSS media queries method
    if (!isMobile) {
      const bodyElement = document.getElementsByTagName('body')[0];
      setIsMobile(
        window
          .getComputedStyle(bodyElement)
          .getPropertyValue('content')
          .indexOf('mobile') !== -1
      );
    }
  }, []);

  return isMobile;
}
