// Absolute imports
import React, { useEffect, useState } from 'react';
import { useTranslation, initReactI18next } from 'react-i18next';

// Relative imports
import Button from '../../../components/button/button';

// Imports from same folder
import types from './types';

function RoleOfOfficer(attr: types) {
  // Hooks
  const { t } = useTranslation('translations');

  return (
    <div className="page introduction">
      <div className="dots" />
      <div className="lines" />
      <div className="topContainer">
        {/* <div className="dots" /> */}
        <div className="image">
          <img src="/images/fotos/DSC_4641-2-Enhanced-NR-2.jpg" />
        </div>
        <div className="contentContainer">
          <div className="textContainer">
            <h3>{t('roleOfOfficePageTitle')}</h3>
            <p className="content">{t('roleOfOfficePageDescription')}</p>
          </div>
        </div>
      </div>
      <div className="bottomContainer bottomBuffer">
        <Button
          title={t('prevPage')}
          enviroment="startPage"
          label={t('prevPage')}
          color=""
          width="140px"
          classes="prev"
          parentFunction={attr.page}
        />
        <Button
          title={t('nextPage')}
          enviroment="context"
          label={t('nextPage')}
          color="#E65100"
          classes="orange"
          width="140px"
          parentFunction={attr.page}
        />
      </div>
    </div>
  );
}

export default RoleOfOfficer;
