// Absolute imports
import React, { useEffect, useState } from 'react';

// Relative imports
import '../dynamicRenderer/dynamicComponent';
import { useGameDispatch, useGameState } from '../../contexts/gameContext';

// Import from same folder
import types from './types';
import { getId, useInterval } from '../../hooks/useInterval';
import { useTranslation } from 'react-i18next';

function CountdownTimer(attr: types) {
  const [previousDateTime, setPreviousDateTime] = useState(Date.now());
  const { t } = useTranslation('translations');
  const { level, cases, intervals } = useGameState();
  const gameDispatch = useGameDispatch();
  let paused = <></>;
  let isPlaying = false;

  const prevTime = sessionStorage.getItem(attr.saveTag);
  const milliseconds = prevTime
    ? Number(prevTime.split(',')[0])
    : attr.seconds * 1000;

  const [countUp, setCountUp] = useState(
    prevTime ? Number(prevTime.split(',')[2]) : 0
  );

  const [countdown, setCountdown] = useState(
    prevTime ? Number(prevTime.split(',')[1]) : milliseconds
  );
  let baseAnswer: string[];
  const [unAnsweredCases, setUnAnsweredCases] = useState<string[]>([]);

  if (!level) {
    return null;
  }

  useEffect(() => {
    level.cases.forEach((item) => {
      unAnsweredCases.push(item.id);
    });

    baseAnswer = unAnsweredCases;
  }, []);

  function checkCurrentSelection() {
    if (!attr.paused) {
      if (!level) {
        return null;
      }
      if (level.selectedCaseId !== '') {
        const prev = Number(sessionStorage.getItem('prevTime')?.split(',')[1]);
        const obj = level?.cases.filter(
          (item) => item.id === level.selectedCaseId
        )[0];
        const outOfTime = obj.time * 1000 - (level.timeInSeconds * 1000 - prev) <= 0;
        if (outOfTime) {
          attr.page('caseExpired');
        }
      }

      if (!cases) {
        return null;
      }
      if (cases.length === level.cases.length - 1) {
        unAnsweredCases.forEach((item) => {
          if (!cases.filter((obj) => obj.id === item)[0]) {
            const prev = Number(sessionStorage.getItem('prevTime')?.split(',')[1]);
            const obj = level?.cases.filter((el) => el.id === item)[0];
            const outOfTime =
              obj.time * 1000 - (level.timeInSeconds * 1000 - prev) <= 0;
            if (outOfTime) {
              attr.page('gameSolved');
            }
          }
        });
      }
    }
  }

  const startTimer = () => {
    const currentDateTime = Date.now();
    const currentTimeSpend = currentDateTime - previousDateTime;
    setPreviousDateTime(currentDateTime);

    if (!attr.paused && isPlaying) {
      checkCurrentSelection();
      const count = countdown - currentTimeSpend;
      setCountdown(count);

      const up = countUp + currentTimeSpend;
      setCountUp(up);

      if (count <= 0) {
        sessionStorage.setItem('sessionEnded', 'true');
        attr.page('endPage');
        isPlaying = false;
        clearInterval(getId());
      }
    }
  };

  const ended = sessionStorage.getItem('sessionEnded');
  const loaded = sessionStorage.getItem('levelLoaded');

  if (!isPlaying && ended !== 'true' && loaded === 'true') {
    isPlaying = true;
    paused = attr.paused ? (
      <div className="paused">
        <strong className="pauseText">{t('pause')}</strong>
      </div>
    ) : (
      <></>
    );
  }

  useInterval(startTimer, 100);

  const strokeDashoffset = () => {
    sessionStorage.setItem(
      attr.saveTag,
      [milliseconds.toString(), countdown, countUp].toString()
    );
    return '';
  };

  if (!level) {
    console.error('No data here!');
    return null;
  }

  const standardMinutes = level.timeInSeconds / 7 / 60;
  const timeup = countUp / 1000 / standardMinutes;
  let upminutes = Math.floor(timeup / 60);
  const upseconds = Math.floor(timeup - upminutes * 60);

  upminutes = upminutes + 8;

  const militaryMin =
    upminutes.toString().length < 2
      ? '0' + upminutes.toString()
      : upminutes.toString();
  const militarySec =
    upseconds.toString().length < 2
      ? '0' + upseconds.toString()
      : upseconds.toString();

  const display = (
    <>
      <p>{militaryMin}</p>
      <p className="small">uur &nbsp;</p>
      <p>{militarySec}</p>
      <p className="small">min</p>
    </>
  );

  return (
    <>
      {paused}
      <div className="timer_container">
        <div className="content">
          {display}
          {isPlaying ? strokeDashoffset() : ''}
        </div>
      </div>
    </>
  );
}
export default CountdownTimer;
