// Absolute imports
import React, { useEffect, useState } from 'react';
import { useTranslation, initReactI18next } from 'react-i18next';

// Relative imports
import Button from '../../../components/button/button';
import types from './types';
import Pagination from '../../../components/pagination/pagination';
import { useGameDispatch } from '../../../contexts/gameContext';
import levelService from '../../../utils/services/dataServices/levelService';
import Level from '../../../utils/models/level';
import { TailSpin } from 'react-loader-spinner';

import * as Globals from '../../../constants/globals';

function Context(attr: types) {
  const [isLoading, setIsLoading] = useState(false);

  // Hooks
  const { t } = useTranslation('translations');
  const gameDispatch = useGameDispatch();
  const startGame = async () => {
    setIsLoading(true);
    await getLevel();
  };

  const getLevel = async () => {
    await levelService.getLevel(
      'nl',
      async (level: Level) => {
        gameDispatch({
          type: Globals.GAMECONTEXT_SETLEVEL,
          payload: { level }
        });

        attr.page('workSpace');
        setIsLoading(false);
        sessionStorage.setItem('levelLoaded', 'true');
      },
      (error: any) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  if (isLoading) {
    return (
      <div className="body introduction">
        <div className="container">
          {/* <div className="dots" /> */}
          <div className="spinner">
            <TailSpin
              height="80"
              width="80"
              color="#01689b"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="page introduction">
        <div className="dots" />
        <div className="lines" />
        <div className="topContainer">
          <div className="image">
            <img src="./images/fotos/DSC_4682-2-Enhanced-NR.jpg" />
          </div>
          <div className="contentContainer">
            <div className="textContainer">
              <h3>{t('explanationTitle')}</h3>
              <p className="content">{t('explanationDescription')}</p>
            </div>
            <Pagination amount={4} index={1} />
          </div>
        </div>
        <div className="bottomContainer">
          <Button
            title={t('prevPage')}
            enviroment="roleOfOfficer"
            label={t('prevPage')}
            color=""
            width="140px"
            classes="prev"
            parentFunction={attr.page}
          />
          <Button
            title={t('nextPage')}
            enviroment="parts"
            label={t('nextPage')}
            color="#E65100"
            width="140px"
            classes="primary"
            parentFunction={attr.page}
          />
        </div>
        <div className="skipContainer">
          <Button
            title={t('skipPage')}
            enviroment=""
            label={t('skipPage')}
            width="140px"
            classes="underline"
            parentFunction={attr.page}
            function={startGame}
          />
        </div>
      </div>
    );
  }
}

export default Context;
