// Absolute imports

// Relative imports
import BaseParser from './parser/BaseParser';

interface Iprio {
  recl: number;
  rvk: number;
  shn: number;
  halt: number;
  pol: number;
}

export default class Priorities extends BaseParser implements Iprio {
  recl = 0;
  rvk = 0;
  shn = 0;
  halt = 0;
  pol = 0;

  static parseList(data: any) {
    const items = [];
    for (const i in data) {
      items.push(this.parse(data[i]));
    }
    return items;
  }

  static parse(object: any) {
    const prios = new Priorities();
    prios.recl = this.parseNumber(object.recl);
    prios.rvk = this.parseNumber(object.rvk);
    prios.shn = this.parseNumber(object.shn);
    prios.halt = this.parseNumber(object.halt);
    prios.pol = this.parseNumber(object.pol);

    return prios;
  }
}
