// Absolute imports
import React, { useEffect, useState } from 'react';
import { useTranslation, initReactI18next } from 'react-i18next';
import * as Globals from '../../../constants/globals';
// Relative imports
import { useGameDispatch, useGameState } from '../../../contexts/gameContext';

// Imports from same folder
import types from './types';
import Button from '../../../components/button/button';
import NavBar from '../../../components/NavBar/NavBar';
import SmallTimer from '../../../components/smallTimer/smallTimer';

function GameSolved(attr: types) {
  // Hooks
  const { t } = useTranslation('translations');
  const { level, cases } = useGameState();
  const gameDispatch = useGameDispatch();

  if (!level) {
    console.error('No data here!');
    return null;
  }

  const clearSessionData = () => {
    gameDispatch({
      type: Globals.GAMECONTEXT_CLEAR
    });
    sessionStorage.removeItem('sessionEnded');
    sessionStorage.removeItem('prevTime');
    sessionStorage.removeItem('levelLoaded');
  };

  function unfold(index: number) {
    const container = document.getElementById('result' + index);
    const checked = document.getElementById('checked' + index) as HTMLInputElement;
    const more = document.getElementById('more' + index);
    const flex = document.getElementById('flex' + index);

    if (container && checked && more && flex) {
      if (checked.checked) {
        const moreInfoButton = t('moreInfoButtonTitle');
        container.style.height = '135px';
        flex.style.height = '135px';
        more.style.top = '60%';
        more.innerHTML = moreInfoButton;
        checked.checked = false;
      } else {
        const lessInfoButton = t('lessInfoButtonTitle');
        container.style.height = 'fit-content';
        flex.style.height = '85px';
        more.style.top = 'unset';
        more.style.bottom = '15px';
        more.innerHTML = lessInfoButton;
        checked.checked = true;
      }
    }
  }

  return (
    <>
      <div className="body gameSolved">
        <div className="nav">
          <NavBar
            page={attr.page}
            backButton={false}
            pauseClock={true}
            alignRight={false}
            currentPage={'gameSolved'}
          />
        </div>
        <div className="lines" />
        <div className="title">
          <h2>{t('caseSolvedTitle')}</h2>
        </div>
        <div className="container">
          <div className="textContent">
            <p>{t('caseSolvedDescription')}</p>
          </div>
          <div className="title">
            <h2>{t('yourChoiceTitle')}</h2>
          </div>
          <div className="casesFeedBack">
            {level.cases.map((item, index) => {
              const correctChoice = item.possibilitiesOfSettlement.filter(
                (obj) => obj.correct
              )[0].title;
              const choice = cases?.filter((obj) => obj.id === item.id)[0];

              const yourChoice = !choice ? t('expired') : choice?.title;

              const info = (
                title: string,
                text: string | undefined,
                style?: string
              ) => {
                return (
                  <>
                    <p className="bold feedbackTitle">{t(title)}</p>
                    <p className={style}>{text}</p>
                  </>
                );
              };

              const title = info('caseTitle', item.title);
              const feedback = info('caseFeedbackTitle', choice?.feedback);

              return (
                <>
                  <div className="caseInfoMonitor" id={'result' + index} key={index}>
                    <div className="contents">
                      <div id={'flex' + index} className="contentsFlex">
                        <div className="case-contents">
                          <div className="textArea">
                            <div className="left">
                              <div className="suspectContent">
                                <div className="suspect">
                                  <p className="bold">{t('suspect')}</p>
                                  <p className="upperCase">{t(item.suspect.name)}</p>
                                </div>
                                <div className="MK">
                                  <p className="bold">{t('MK')}</p>
                                  <p className="upperCase">{t(item.mk)}</p>
                                </div>
                              </div>
                            </div>
                            <div className="right">
                              <div className="suspectContent">
                                <p className="bold">{t('birthDate')}</p>
                                <p>{t(item.suspect.date)}</p>
                              </div>
                            </div>
                          </div>

                          <div className="timer">
                            <SmallTimer.SmallTimer
                              seconds={item.time}
                              size={50}
                              parentTag={'prevTime'}
                              saveTag={String(item.title) + 'Timer'}
                              MainTimerAmount={level.timeInSeconds}
                              addition={'(BTO)'}
                              caseId={item.id}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="choiceFeedBack">
                        <p className="bold feedbackTitle">{t('yourChoiceTitle')}</p>
                        <p>{yourChoice}</p>
                        {title}
                        {feedback}
                      </div>
                    </div>

                    <input
                      id={'checked' + index}
                      type={'radio'}
                      style={{ display: 'none' }}
                    />
                    <button
                      id={'more' + index}
                      className="moreInfo"
                      onClick={() => {
                        unfold(index);
                      }}
                    >
                      {t('moreInfoButtonTitle')}
                    </button>
                  </div>
                </>
              );
            })}
          </div>
          <div className="bottomContainer">
            <Button
              title={t('quit')}
              enviroment="startPage"
              label={t('quit')}
              color="#E65100"
              width="140px"
              classes="primary"
              parentFunction={attr.page}
              function={clearSessionData}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default GameSolved;
