// Absolute imports
import React, { useEffect, useState } from 'react';
import { useTranslation, initReactI18next } from 'react-i18next';

// Relative imports
import { useGameDispatch, useGameState } from '../../../contexts/gameContext';

// import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

// Imports from same folder
import types from './types';
import chat from '../../../utils/models/chat';
import {
  GAMECONTEXT_HALTCHAT,
  GAMECONTEXT_POLCHAT,
  GAMECONTEXT_RECLCHAT,
  GAMECONTEXT_RVKCHAT,
  GAMECONTEXT_SHNCHAT
} from '../../../constants/globals';
import { chatHistory } from '../../../utils/models/level';
import NavBar from '../../../components/NavBar/NavBar';

function Chat(attr: types) {
  const { t } = useTranslation('translations');
  const { level } = useGameState();
  const gameDispatch = useGameDispatch();
  const [chat, setChat] = useState(<></>);
  const [chatLog, setChatLog] = useState(Array<JSX.Element>());
  const [messageSend, setMessageSend] = useState(false);
  const [answered, setAnswered] = useState(true);
  const [questions, setQuestions] = useState(<></>);
  const [newQuestions, setNewQuestions] = useState(false);

  const [personImage, setPersonImage] = useState(<></>);
  const [icon, setIcon] = useState(<></>);

  if (!level) {
    console.error('No data here!');
    return null;
  }

  const caseData = level.cases.filter((item) => item.id === level.selectedCaseId)[0];
  const chatData = caseData.counterParties.filter(
    (obj) => obj.id === level.selectedParty
  )[0];

  let i = 0;

  useEffect(() => {
    if (i === 0) {
      loadChat().catch(console.error);
      i++;
    }
  }, []);

  async function timeout(delay: number) {
    return await new Promise((resolve) => setTimeout(resolve, delay));
  }

  const delay = async (delayInms: number) => {
    return await new Promise((resolve) => setTimeout(resolve, delayInms));
  };

  async function askQuestion(item: chat, subQuestions: chat[]) {
    setMessageSend(true);
    setAnswered(false);

    const user = (
      <div className="message right">
        <div className="userMessage">
          <p>{item.question}</p>
        </div>
      </div>
    );
    const bot = (
      <div className="message left">
        <div className="botMessage">
          <p>{item.answer}</p>
        </div>
      </div>
    );
    await insertMessages(user, bot, subQuestions);
    item.answered = true;
  }

  async function insertMessages(
    user: JSX.Element,
    bot: JSX.Element,
    subQuestions: chat[]
  ) {
    chatLog.push(user);
    await updateChat();
    const wait = await delay(3000);
    chatLog.push(bot);
    setAnswered(true);
    getSubQuestions(subQuestions);
    await updateChat();

    storeChat();
  }

  function storeChat() {
    let Data = getSaveData();

    if (!level) {
      console.error('No data here!');
      return null;
    }

    const property = level?.selectedCaseId;
    if (Data) {
      const items = Array<{ class: string; text: string }>();
      chatLog.forEach((item) => {
        items.push({
          class: item.props.className,
          text: item.props.children.props.children.props.children
        });
      });
      Data = { [property]: items };
      saveData(Data);
    } else {
      const items = Array<{ class: string; text: string }>();
      chatLog.forEach((item) => {
        items.push({
          class: item.props.className,
          text: item.props.children.props.children.props.children
        });
      });
      const newData = { [property]: items };
      saveData(newData);
    }
  }

  function getSaveData() {
    switch (level?.selectedParty) {
      case 'POL': {
        return level.polChat;
      }
      case 'HALT': {
        return level.haltChat;
      }
      case 'SHN': {
        return level.shnChat;
      }
      case 'RVK': {
        return level.rvkChat;
      }
      case 'RECL': {
        return level.reclChat;
      }
      default: {
        console.error(
          '!!!! no case ID was found, <br> only found:',
          level?.selectedParty
        );
      }
    }
  }

  function saveData(data: chatHistory) {
    switch (level?.selectedParty) {
      case 'POL': {
        gameDispatch({
          type: GAMECONTEXT_POLCHAT,
          payload: { polChat: data }
        });
        return;
      }
      case 'HALT': {
        gameDispatch({
          type: GAMECONTEXT_HALTCHAT,
          payload: { haltChat: data }
        });
        return;
      }
      case 'SHN': {
        gameDispatch({
          type: GAMECONTEXT_SHNCHAT,
          payload: { shnChat: data }
        });
        return;
      }
      case 'RVK': {
        gameDispatch({
          type: GAMECONTEXT_RVKCHAT,
          payload: { rvkChat: data }
        });
        return;
      }
      case 'RECL': {
        gameDispatch({
          type: GAMECONTEXT_RECLCHAT,
          payload: { reclChat: data }
        });
      }
    }
  }

  async function updateChat() {
    let chatBoard = <></>;
    chatLog.forEach((item) => {
      chatBoard = (
        <>
          {chatBoard}
          {item}
        </>
      );
    });

    setChat(<>{chatBoard}</>);

    await timeout(100);

    const element = document.getElementById('bottomMessage');
    element?.scrollIntoView({ behavior: 'smooth' });
  }

  function getSubQuestions(subQuestions: chat[]) {
    if (subQuestions.length > 0) {
      const newQuestions = (
        <>
          {subQuestions.map(function (item: chat, index: number) {
            return (
              <SwiperSlide key={index}>
                <div>
                  <p>{item.question}</p>
                  <button
                    className="send"
                    onClick={async () => {
                      await askQuestion(item, item.subQuestions);
                    }}
                    disabled={!answered}
                  >
                    <p>{t('askQuestion')}</p>
                  </button>
                </div>
              </SwiperSlide>
            );
          })}
        </>
      );
      setQuestions(newQuestions);
      setNewQuestions(true);
    } else {
      setNewQuestions(false);
    }
  }

  function setStyling() {
    switch (level?.selectedParty) {
      case 'POL': {
        setPersonImage(
          <>
            <img className="image" src="/images/Persoon-Politie.png" />
            <div className="iconContainer">
              <img className="chat-pol" src="/Logos/pol.svg" />
            </div>
          </>
        );
        return;
      }
      case 'HALT': {
        setPersonImage(
          <>
            <img className="image" src="/images/Persoon-Halt.png" />
            <div className="iconContainer">
              <img className="chat-halt" src="/Logos/Halt.svg" />
            </div>
          </>
        );
        return;
      }
      case 'SHN': {
        setPersonImage(
          <>
            <img className="image" src="/images/Persoon-Slachtofferhulp.png" />
            <div className="iconContainer">
              <img className="chat-shn" src="/Logos/shn.svg" />
            </div>
          </>
        );
        return;
      }
      case 'RVK': {
        setPersonImage(
          <>
            <img className="image" src="/images/Persoon-Kinderbescherming.png" />
            <div className="iconContainer">
              <img className="chat-rvk" src="/Logos/rvk.svg" />
            </div>
          </>
        );
        return;
      }
      case 'RECL': {
        setPersonImage(
          <>
            <img className="image" src="/images/Persoon-Reclassering.png" />
            <div className="iconContainer">
              <img className="chat-rvk" src="/Logos/recl.svg" />
            </div>
          </>
        );
      }
    }
  }

  async function loadChat() {
    setStyling();
    const data = getSaveData();
    if (!level || !data) {
      console.error('No data here!');
      return null;
    }

    const history = data[level.selectedCaseId];
    if (history) {
      setMessageSend(true);
      history.forEach((item) => {
        const msg = (
          <div className={item.class}>
            <div
              className={
                item.class === 'message right' ? 'userMessage' : 'botMessage'
              }
            >
              <p>{item.text}</p>
            </div>
          </div>
        );
        chatLog.push(msg);
      });
    }

    await updateChat();
  }
  let mainQuestions = 0;

  return (
    <div className="body chat">
      <div className="nav">
        <NavBar
          page={attr.page}
          backButton={true}
          back={'roundTable'}
          pauseClock={false}
          alignRight={false}
          currentPage={'chat'}
        />
      </div>

      <div className="bottomContent">
        <div
          id="profile"
          className={
            messageSend ? 'profileIconContainer--bottomLeft' : 'profileIconContainer'
          }
        >
          <div className="icon">{personImage}</div>
        </div>

        <div className="questionGrouping">
          <p className="selectQuestion">{t('selectQuestion')}</p>
          <div className="QuestionsContainer">
            <Swiper
              modules={[Navigation]}
              initialSlide={0}
              autoHeight={false}
              direction={'horizontal'}
              spaceBetween={100}
              slidesPerView={'auto'}
              navigation={true}
              centeredSlides={true}
              slidesOffsetBefore={0}
            >
              {!newQuestions
                ? chatData.chat.map(function (item: chat, index: number) {
                    if (!item.answered) {
                      mainQuestions++;
                      return (
                        <SwiperSlide key={index}>
                          <div>
                            <p>{item.question}</p>
                            <button
                              className="send"
                              onClick={async () => {
                                await askQuestion(item, item.subQuestions);
                              }}
                              disabled={!answered}
                            >
                              <p>{t('askQuestion')}</p>
                            </button>
                          </div>
                        </SwiperSlide>
                      );
                    } else {
                      return '';
                    }
                  })
                : questions}
              {mainQuestions === 0 ? (
                <SwiperSlide>
                  <div>
                    <p>{t('noMoreQuestions')}</p>
                  </div>
                </SwiperSlide>
              ) : (
                ''
              )}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="chatContainer">
        <div className="message buffer" />
        <div className="message left">
          <div className="botMessage">
            <p>{chatData.description}</p>
          </div>
        </div>
        {chat}
        <div id="bottomMessage" />
      </div>
    </div>
  );
}

export default Chat;
