import React from 'react';
import types from './types';

function Pagination(attr: types) {
  let pagination = <></>;

  for (let i = 0; i < attr.amount; i++) {
    pagination =
      attr.index - 1 === i ? (
        <>
          {pagination} <div className="item item-selected" />
        </>
      ) : (
        <>
          {pagination} <div className="item" />
        </>
      );
  }

  return <div className="pagination">{pagination}</div>;
}
export default Pagination;
