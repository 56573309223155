// Absolute imports
import i18next from 'i18next';

// Relative imports
import api from '../Api';
import Level from '../../models/level';

const levelService = {
  getLevel: async (langId: string, responseCallBack: any, errorCallBack: any) => {
    const response = await api.create().get('/level.json', {});

    if (response.success) {
      responseCallBack(Level.parse(response.data));
    } else {
      errorCallBack(response);
    }
  }
};

export default levelService;
