// Absolute imports
import React, { useEffect, useState } from 'react';
import { useTranslation, initReactI18next } from 'react-i18next';

// Relative imports
import { useGameState, useGameDispatch } from '../../../contexts/gameContext';
import Button from '../../../components/button/button';

// Imports from same folder
import types from './types';
import * as Globals from '../../../constants/globals';
import NavBar from '../../../components/NavBar/NavBar';

function RoundTable(attr: types) {
  // Hooks
  const { t } = useTranslation('translations');
  const { level } = useGameState();
  const gameDispatch = useGameDispatch();
  const [buttonElement, setButtonElement] = useState(<></>);
  // Render
  if (!level) {
    console.error('No data here!');
    return null;
  }
  let i = 0;
  useEffect(() => {
    if (i === 0) {
      if (level?.selectedCaseId) {
        setButtonElement(<></>);
      } else {
        setButtonElement(
          <>
            <Button
              title="Je moet eerst een case selecteren"
              enviroment="suspectMonitor"
              label={t('back')}
              width="219px"
              height="28px"
              classes="underline"
              parentFunction={attr.page}
            />
          </>
        );
      }
      i++;
    }
  }, []);

  function select(e: any) {
    gameDispatch({
      type: Globals.GAMECONTEXT_SETSELECTEDPARTY,
      payload: { selectedParty: e.target.id }
    });

    setButtonElement(
      <div className="buttonContainer">
        <div className="chatInfo">
          <p>
            {level?.selectedCaseId ? (
              <>
                {t('startChatWith')} {e.target.value}
              </>
            ) : (
              <>{t('selectCaseFirst')}</>
            )}
          </p>
        </div>
        <div className="chatButton">
          <Button
            enviroment="chat"
            custom={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z" />
              </svg>
            }
            label={t('back')}
            color="#E65100"
            width="64px"
            height="64px"
            classes="orange"
            parentFunction={attr.page}
            disabled={level?.selectedCaseId === null || level?.selectedCaseId === ''}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="body roundTable">
        <div className="elipsis" />
        <div className="nav">
          <NavBar
            page={attr.page}
            backButton={true}
            pauseClock={false}
            alignRight={false}
            currentPage={'roundTable'}
          />
        </div>
        <h2>{t('roundTablePageTitle')}</h2>

        <p className="subHeading">{t('roundTablePageSubDescription')}</p>
        <div className="table-content">
          <div className="radioButtons">
            <label>
              <input
                type="radio"
                className="pol"
                id="POL"
                name="ketenPartners"
                value="Politie"
                onClick={select}
                disabled={!level?.selectedCaseId}
              />
              <div className="imageContainer">
                <img
                  className="pol pol-logo"
                  src={
                    !level?.selectedCaseId
                      ? '/Logos/pol-disabled.svg'
                      : '/Logos/pol.svg'
                  }
                />
              </div>
            </label>
            <br />
            <label>
              <input
                type="radio"
                className="hlt"
                id="HALT"
                name="ketenPartners"
                value="Halt"
                onClick={select}
                disabled={!level?.selectedCaseId}
              />
              <img
                className="hlt hlt-logo"
                src={
                  !level?.selectedCaseId
                    ? '/Logos/Halt-disabled.svg'
                    : '/Logos/Halt.svg'
                }
              />
            </label>
            <br />
            <label>
              <input
                type="radio"
                className="shn"
                id="SHN"
                name="ketenPartners"
                value="Slachtofferhulp Nederland"
                onClick={select}
                disabled={!level?.selectedCaseId}
              />
              <img
                className="shn shn-logo"
                src={
                  !level?.selectedCaseId
                    ? '/Logos/shn-disabled.svg'
                    : '/Logos/shn.svg'
                }
              />
            </label>
            <br />
            <label>
              <input
                type="radio"
                className="rvk"
                id="RVK"
                name="ketenPartners"
                value="Raad van Kinderbescherming"
                onClick={select}
                disabled={!level?.selectedCaseId}
              />
              <img
                className="rvk rvk-logo"
                src={
                  !level?.selectedCaseId
                    ? '/Logos/rvk-disabled.svg'
                    : '/Logos/rvk.svg'
                }
              />
            </label>
            <br />
            <label>
              <input
                type="radio"
                className="recl"
                id="RECL"
                name="ketenPartners"
                value="Reclassering"
                onClick={select}
                disabled={!level?.selectedCaseId}
              />
              <img
                className="recl recl-logo"
                src={
                  !level?.selectedCaseId
                    ? '/Logos/recl-disabled.svg'
                    : '/Logos/recl.svg'
                }
              />
            </label>
          </div>
        </div>
        {buttonElement}
      </div>
    </>
  );
}

export default RoundTable;
