// Absolute imports
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';

// Relative imports
import { GameProvider } from './contexts/gameContext';
import App from './enviroments/App';
import reportWebVitals from './reportWebVitals';
import i18n from 'i18next';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as Globals from './constants/globals';
import './styling/main.scss';

void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      nl: {
        translation: {
          appName: 'Welcome react-i18next'
        }
      }
    },
    lng: Globals.DEFAULT_LANG, // if you're using a language detector, do not define the lng option
    fallbackLng: Globals.DEFAULT_LANG,

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <GameProvider>
      <App />
    </GameProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
