import React, { useState } from 'react';
import types from './types';
import { useTranslation } from 'react-i18next';
import Button from '../button/button';
import { useGameState } from '../../contexts/gameContext';
import SmallTimer from '../smallTimer/smallTimer';
import { useInterval } from '../../hooks/useInterval';

function CaseInfo(attr: types) {
  const { t } = useTranslation('translations');
  const { level, cases } = useGameState();
  const milliseconds = Number(attr.selectedCase.time * 1000);
  const [countdown, setCountdown] = useState(milliseconds);

  if (!level) {
    console.error('No data here!');
    return null;
  }

  const mainCountDownTime = level.timeInSeconds * 1000;

  const handleHeaderClick = (event: any) => {
    event.stopPropagation();
  };

  useInterval(startTimer, 10);

  const isSolved: boolean = SmallTimer.isCaseSolved(cases, level.selectedCaseId);

  function startTimer() {
    const prev = sessionStorage.getItem('prevTime');

    if (countdown > 0 || !isSolved) {
      if (prev) {
        setCountdown(
          milliseconds - (mainCountDownTime - Number(prev.split(',')[1]))
        );
      }
    }
  }

  const state = SmallTimer.setStateClass(level, countdown);

  return (
    <>
      <div
        className={'caseInfo ' + (attr.alignRight ? 'caseInfo-right' : '')}
        onClick={handleHeaderClick}
      >
        <div className="contents">
          <div>
            <div className={'triangle triangle-' + state} />
            <div className={'topBorder topBorder-' + state}>
              <p>{t('selectedCase')}</p>
            </div>
            <div className="case-contents">
              <div className="textArea">
                <div className="left bottom">
                  <div className="suspect bottom">
                    <p className="bold">{t('suspect')}</p>
                    <p>{t(attr.selectedCase.suspect.name)}</p>
                  </div>
                  <div className="suspect">
                    <p className="bold">{t('MK')}</p>
                    <p>{t(attr.selectedCase.mk)}</p>
                  </div>
                </div>
                <div className="timer">
                  <SmallTimer.SmallTimer
                    seconds={attr.selectedCase.time}
                    size={50}
                    parentTag={'prevTime'}
                    saveTag={String(attr.selectedCase.title) + 'Timer'}
                    MainTimerAmount={level.timeInSeconds}
                    caseId={level.selectedCaseId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CaseInfo;
