import React, { useEffect, useRef } from 'react';
import { GAMECONTEXT_SETINTERVAL } from '../constants/globals';
import { useGameDispatch, useGameState } from '../contexts/gameContext';

let id: NodeJS.Timer;

const useInterval = (callback: any, delay: number) => {
  const savedCallback = useRef<any>();
  const { intervals } = useGameState();
  const gameDispatch = useGameDispatch();

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      id = setInterval(tick, delay);

      if (!intervals) {
        gameDispatch({
          type: GAMECONTEXT_SETINTERVAL,
          payload: { intervals: [id] }
        });
      } else {
        const intvArr = intervals;
        intvArr.push(id);
        gameDispatch({
          type: GAMECONTEXT_SETINTERVAL,
          payload: { intervals: intvArr }
        });
      }

      return () => {
        clearInterval(id);
      };
    }
  }, [delay]);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
};

const getId = () => {
  return id;
};

export { useInterval, getId };
