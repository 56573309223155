// Absolute imports

// Relative imports

export const GAMECONTEXT_STATE = 'gameContextState';
export const GAMECONTEXT_RESTORESTATE = 'restoreGameContextState';
export const GAMECONTEXT_SETLEVEL = 'setLevel';
export const GAMECONTEXT_SETCASEDECISIONS = 'setCaseDecisions';
export const GAMECONTEXT_SETINTERVAL = 'setInterval';
export const GAMECONTEXT_SETSELECTEDCASEID = 'setSelectedCaseId';
export const GAMECONTEXT_SETSELECTEDCASETITLE = 'setSelectedCaseTitle';
export const GAMECONTEXT_SETPREVPAGE = 'setPrevPage';

export const GAMECONTEXT_SETSELECTEDPARTY = 'setSelectedParty';

export const GAMECONTEXT_SETSOLVED = 'setSolved';
// chat history
// chat history
export const GAMECONTEXT_POLCHAT = 'POL';
export const GAMECONTEXT_HALTCHAT = 'HALT';
export const GAMECONTEXT_SHNCHAT = 'SHN';
export const GAMECONTEXT_RVKCHAT = 'RVK';
export const GAMECONTEXT_RECLCHAT = 'RECL';

export const GAMECONTEXT_CLEAR = 'clearData';

export const GAMECONTEXT_TIME = 'setTime';

export const DEFAULT_LANG = 'nl';
export const PAGE_SIZE = 20;
export const WEBSERVICE_DATEFORMAT = 'YYYY-MM-DD';
