// Absolute imports
import React, { useEffect, useState } from 'react';
import { useTranslation, initReactI18next } from 'react-i18next';

// Relative imports
import { useGameDispatch, useGameState } from '../../../contexts/gameContext';

// Imports from same folder
import types from './types';
import NavBar from '../../../components/NavBar/NavBar';
import Button from '../../../components/button/button';

function Feedback(attr: types) {
  // Hooks
  const { t } = useTranslation('translations');
  const { level, cases } = useGameState();

  if (!level) {
    console.error('No data here!');
    return null;
  }
  const feedBack = cases?.filter((item) => item.id === level.selectedCaseId)[0]
    .feedback;

  return (
    <>
      <div className="body feedBack">
        <div className="lines" />
        <div className="nav">
          <NavBar
            page={attr.page}
            hideInstructions={false}
            backButton={true}
            back={level.solved ? 'gameSolved' : 'suspectMonitor'}
            pauseClock={true}
            alignRight={false}
            timerVisible={true}
            currentPage={'feedback'}
          />
        </div>
        <div className="container">
          <div className="title">
            <h2>{t('feedback')}</h2>
          </div>
          <div className="textContent">
            <p>{feedBack}</p>
          </div>
        </div>
        <div className="bottomContainer bottomBuffer">
          <Button
            title={t('backToStart')}
            enviroment={level.solved ? 'gameSolved' : 'workSpace'}
            label={t('backToStart')}
            color="#E65100"
            width="140px"
            classes="primary"
            parentFunction={attr.page}
          />
        </div>
      </div>
    </>
  );
}

export default Feedback;
