// Absolute imports

// Relative imports
import BaseParser from './parser/BaseParser';

interface ISettlement {
  id: string;
  title: string;
  feedback: string;
  correct: boolean;
  outOfTime: boolean;
}

export default class Settlement extends BaseParser implements ISettlement {
  id = '';
  title = '';
  feedback = '';
  correct = false;
  outOfTime = false;

  static parseList(data: any): Settlement[] {
    const items = [];
    for (const i in data) {
      items.push(this.parse(data[i]));
    }
    return items;
  }

  static parse(object: any) {
    const settlement = new Settlement();
    settlement.id = this.parseString(object.id);
    settlement.title = this.parseString(object.title);
    settlement.feedback = this.parseString(object.feedback);
    settlement.correct = this.parseBool(object.correct);
    settlement.outOfTime = this.parseBool(object.outOfTime);

    return settlement;
  }
}
