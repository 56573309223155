import React from 'react';
import types from './types';
import '../dynamicRenderer/dynamicComponent';
import { useGameDispatch, useGameState } from '../../contexts/gameContext';
import {
  GAMECONTEXT_SETINTERVAL,
  GAMECONTEXT_SETPREVPAGE
} from '../../constants/globals';

function Button(attr: types) {
  const { prevPage, intervals } = useGameState();
  const gameDispatch = useGameDispatch();
  function redirect() {
    if (intervals) {
      // this function makes sure all intervals are cleared and stopped very important for the pause functionality
      intervals.forEach((item) => {
        clearInterval(item);
      });
      intervals.length = 0;
      gameDispatch({
        type: GAMECONTEXT_SETINTERVAL,
        payload: { intervals }
      });
    }

    if (attr.parentFunction) {
      attr.parentFunction(attr.enviroment);
    }
    gameDispatch({
      type: GAMECONTEXT_SETPREVPAGE,
      payload: { prevPage: attr.currentPage }
    });

    console.log(prevPage);
  }

  const exectute = () => {
    if (attr.enviroment) {
      redirect();
    }
    attr.function?.();
  };

  const classes = String(attr.classes) + ' button';

  const title = attr.title ? (
    <div className="text">
      <p>{attr.title}</p>
    </div>
  ) : (
    <></>
  );
  const content = attr.custom ? attr.custom : <></>;

  return (
    <div className={classes}>
      <button
        style={{
          backgroundColor: attr.color,
          width: attr.width,
          height: attr.height
        }}
        onClick={() => {
          exectute();
        }}
        disabled={attr.disabled}
      >
        {title}
        {content}
      </button>
    </div>
  );
}
export default Button;
